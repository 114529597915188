import { Col, Row } from "antd";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { ArrowRight } from "iconsax-react";
import ButtonBox from "../../Components/ButtonBox/ButtonBox";
import Roadmap from "./Roadmap";
import NewsletterSubscription from "../SubscribeNewsletter/NewsletterSubscription";
import AssetPath from "../../AssetPath/AssetPath";

const servicesList = [
  {
    title: "Ways of Working",
    image: AssetPath.images.waysofWorking,
    link: "/ways-of-working",
    color: "var(--primary)",
  },
  {
    title: "Ideas & Innovation",
    image: AssetPath.images.ideasInnovation,
    link: "/ideas-and-innovation",
    color: "var(--secondary)",
  },
  {
    title: "Initiatives",
    image: AssetPath.images.initiatives,
    link: "/initiatives",
    color: "var(--purple)",
  },
  {
    title: "Value & Impact Creation",
    image: AssetPath.images.valuesandImpactCreation,
    link: "/values-and-impact-creation",
    color: "var(--green)",
  },
];

const practiclFun = [
  {
    icon: AssetPath.icon.experiment,
    title: "90 Days Experiment",
    description:
      "“Action speaks louder than words” Our experiments are designed to help you make significant shifts in a practical manner",
  },
  {
    icon: AssetPath.icon.consulting,
    title: "Consulting",
    description:
      "“Seeing Things Differently” We help you see things from different angles, explore new possibilities and adopt new ways of thinking and doing",
  },
  {
    icon: AssetPath.icon.helpingHands,
    title: "Helping hands",
    description:
      "“The power of collective” Our team of experts and partners can work with you/ be a part of your team to see you through to success",
  },
  {
    icon: AssetPath.icon.learnaThrons,
    title: "Learn-a-thons",
    description:
      "Lets make workshops less boring. Let's make them fun, packed with learning and new experiences!",
  },
];

export default function Services() {
  const navigate = useHistory();

  return (
    <>
      <section className="service-title-section bg-lightyellow-gradient spacing position-relative">
        <div className="container">
          <img
            src={AssetPath.shapes.circleTransparent}
            className="circle-transparent position-absolute"
            alt="Simple ideas GmbH"
          />
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 40 },
              { xs: 0, sm: 16, md: 24, lg: 40 },
            ]}
            justify="center"
            align="middle"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              data-aos="fade-up"
            >
              <div className="position-relative z-index" data-aos="fade-up">
                <h1
                  className="font-2 font-weight-medium text-uppercase mb-3"
                  data-aos="fade-up"
                >
                  Our Services
                </h1>
                <h2 className="h1 text-black mb-3" data-aos="fade-up">
                  Simple Solutions for Big Results
                </h2>
                <p className="mb-3" data-aos="fade-up">
                  Streamlined solutions delivering impactful results with
                  simplicity at core.
                </p>
                <div className="d-flex">
                  <Link
                    to="/contact-us"
                    className="ant-btn ant-btn-primary"
                    data-aos="fade-up"
                  >
                    Let’s talk <span className="arrow-right"></span>
                    <ArrowRight className="ms-2" />
                  </Link>
                </div>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              data-aos="fade-up"
            >
              <div className="position-relative z-index">
                <div className="text-end">
                  <img
                    src={AssetPath.images.SimpleIdeasGmbhServices}
                    alt="Simple Ideas Gmbh Services"
                    data-aos="zoom-up"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="service-help spacing position-relative">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 40 },
              { xs: 0, sm: 16, md: 24, lg: 40 },
            ]}
            justify="center"
            align="middle"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
              data-aos="fade-up"
            >
              <p className="mb-3" data-aos="fade-up">
                At "Simple Ideas we help you create a modern, action-driven
                organization that prioritizes people and delivers results. We
                believe people are the core of any thriving organization. Our
                approach revolves around simplicity. In a world full of
                complexities, we emphasize simplicity and need for action over
                words. Our expert services, delivered by our partner network,
                merge industry expertise with practicality. While you're
                responsible for the outcomes, we're here to support you every
                step of the way!
              </p>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
              data-aos="fade-up"
            >
              <div className="text-end">
                <img
                  src={AssetPath.images.servicesImg}
                  alt="Simple Ideas Gmbh Services"
                  data-aos="zoom-up"
                  className="w-75"
                />
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="spacing section-top1 position-relative similar-services">
        <img
          src={AssetPath.shapes.circle}
          alt="circle"
          className="position-absolute circle"
        />
        <div className="container position-relative ">
          <div className="d-flex align-items-center flex-column flex-md-row flex-lg-row justify-content-between  mb-2">
            <div className="heading">
              <h4 className="h3">Check out our Services</h4>
              <p>
                Whether you’re starting up or in the Fortune Global 500, tap
                into our broad range of{" "}
                <span className="d-flex w-100">
                  services and solutions to help your business thrive.
                </span>
              </p>
            </div>
            <div className="select-services">
              <ButtonBox
                rightArrowIcon
                label="Select Services"
                onClick={() => navigate.push("/service-selection")}
              />
            </div>
          </div>
          <Row
            gutter={[
              { xs: 10, sm: 16, md: 24, lg: 24 },
              { xs: 10, sm: 16, md: 24, lg: 24 },
            ]}
            justify="center"
            align="middle"
          >
            {servicesList.map((data, i) => (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                key={i}
              >
                <Link data-aos="fade-up" to={data.link} className="text-black">
                  <div className="services-list border-radius bg-white">
                    <h4>{data.title}</h4>
                    <img src={data.image} alt={data.title} />
                    <div className="d-flex align-items-center justify-content-between mt-3">
                      <span className="text-black">
                        Know more <ArrowRight className="ms-2" />
                      </span>
                      <div
                        className="count"
                        style={{ WebkitTextStrokeColor: data.color }}
                      >
                        {i + 1}
                      </div>
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      </section>

      <section className="practical-fun spacing">
        <div className="container">
          <h4 className="h3 text-center mb-3 font-weight-semibold">
            Some of the ways in which we deliver our services!{" "}
            <span className="text-primary">Practical & Fun</span>
          </h4>
          <Row
            gutter={[
              { xs: 0, sm: 12, md: 12, lg: 12 },
              { xs: 0, sm: 12, md: 12, lg: 12 },
            ]}
          >
            {practiclFun.map((data, i) => (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
                key={i}
                data-aos="fade-up"
              >
                <div className="list text-center mb-3">
                  <div className="icon mb-2">
                    <img src={data.icon} alt={data.title} />
                  </div>
                  <h5 className="h4">{data.title}</h5>
                  <p>{data.description}</p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </section>

      <Roadmap />

      <NewsletterSubscription className="mt-4" />
    </>
  );
}
