import React from "react";
import { Formik, Field, Form } from "formik";
import "./Contact.scss";
import apiClient from "../../Actions/apiClient";
import { message } from "antd";
import { useHistory } from "react-router-dom";

export default function ContactForm() {
  const history = useHistory();
  const onSend = (values) => {
    apiClient.post("contactus/add", values).then(({ data }) => {
      if (data?.result) {
        message.success(
          "Thanks for contacting us! Our team will reach out to you shortly"
        );
        history.push("/");
      }
    });
  };

  return (
    <div className="contact-form">
      <Formik
        initialValues={{
          name: "",
          email: "",
          company: "",
          phone: "",
          services: "",
          message: "",
        }}
        onSubmit={onSend}
      >
        <Form>
          <div className="row">
            <div className="field col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label>Full Name *</label>
              <Field
                name="name"
                placeholder="Enter your full name.."
                type="text"
                required
              />
            </div>
            <div className="field col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label>Email Address *</label>

              <Field
                name="email"
                placeholder="Enter your email address.."
                type="email"
                required
              />
            </div>
            <div className="field col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label>Company *</label>
              <Field name="company" placeholder="Enter your company.." />
            </div>
            <div className="field col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label>Location *</label>
              <Field name="location" placeholder="Enter your location.." />
            </div>
            <div className="field col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label>Phone Number *</label>
              <Field
                name="phone"
                placeholder="Enter your Phone Number.."
                type="phone"
              />
            </div>
            <div className="field col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label>Services *</label>

              <Field as="select" name="services" required>
                <option value="ways-of-working">Ways of Working</option>
                <option value="ideas-and-innovation">Ideas & Innovation</option>
                <option value="initiatives">Initiatives</option>
                <option value="values-and-impact-creation">
                  Values & Impact Creation
                </option>
              </Field>
            </div>
            <div className="field col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>Message *</label>

              <Field
                as="textarea"
                placeholder="Write your message"
                name="message"
                type="text"
                required
              />
            </div>
          </div>
          <button className="ant-btn ant-btn-primary w-100" type="submit">
            Submit
          </button>
        </Form>
      </Formik>
    </div>
  );
}
