import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../screens/LandingPage/Home";
import AboutUs from "../screens/AboutUs/AboutUs";
import Layout from "../Layout/Layout";
import Contact from "../screens/Contact/Contact";
import Partners from "../screens/Partners/Partners";
import FAQ from "../screens/FAQ/FAQ";
import Login from "../screens/Login/Login";
import NinetyDaysExperiment from "../screens/NinetyDaysExperiment/NinetyDaysExperiment";

// services
import Services from "../screens/Services/Services";
import ServiceSelection from "../screens/ServiceSelection/ServiceSelection";
import WaysofWorking from "../screens/Services/WaysofWorking";
import IdeasandInnovation from "../screens/Services/IdeasandInnovation";
import ValuesandImpactCreation from "../screens/Services/ValuesandImpactCreation";
import Initiatives from "../screens/Services/Initiatives";

// privacy info
import Privacy from "../screens/Privacy/Privacy";
import TermsandConditions from "../screens/TermsandConditions/TermsandConditions";
import PageNotFound from "../screens/PageNotFound/PageNotFound";

// blogs
import Blogs from "../screens/Blogs/Blogs";
import KeepitSimplySilly from "../screens/Blogs/BlogPages/KeepitSimplySilly";
import UnleashThePowerOfSimplicity from "../screens/Blogs/BlogPages/UnleashThePowerOfSimplicity";
import KickingComplexProblems from "../screens/Blogs/BlogPages/KickingComplexProblems";
import TheCurseofComplexity from "../screens/Blogs/BlogPages/TheCurseofComplexity";
import NavigatingWorkplaceComplexity from "../screens/Blogs/BlogPages/NavigatingWorkplaceComplexity";
import AgilevsRealAgility from "../screens/Blogs/BlogPages/AgilevsRealAgility";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import Careers from "../screens/Careers/Careers";
class InnerRoutes extends React.Component {
  render() {
    const {
      match: { url },
    } = this.props;
    return (
      <Layout {...this.props}>
        <Switch>
          <Route exact path={`/`} component={Home} />
          <Route path={`/about-us`} component={AboutUs} />
          <Route path={`/contact-us`} component={Contact} />
          <Route path={`/become-a-partner`} component={Partners} />
          <Route path={`/faq`} component={FAQ} />
          <Route
            path={`/90-days-experiment`}
            component={NinetyDaysExperiment}
          />
          {/* services details */}
          <Route path={`/services`} component={Services} />
          <Route path={`/service-selection`} component={ServiceSelection} />
          <Route path={`/ways-of-working`} component={WaysofWorking} />
          <Route
            path={`/ideas-and-innovation`}
            component={IdeasandInnovation}
          />
          <Route path={`/initiatives`} component={Initiatives} />
          <Route
            path={`/values-and-impact-creation`}
            component={ValuesandImpactCreation}
          />

          {/* privacy info */}
          <Route path={`/privacy-policy`} component={Privacy} />
          <Route
            path={`/terms-and-conditions`}
            component={TermsandConditions}
          />
          <Route path="/career" component={Careers} />
          {/* blogs */}
          <Route path="/blogs" component={Blogs} />
          <Route
            path="/keep-it-simple-silly-how-a-post-it-note-saved-the-day-at-the-office"
            component={KeepitSimplySilly}
          />
          <Route
            path="/unleash-the-power-of-simplicity-a-workplace-odyssey"
            component={UnleashThePowerOfSimplicity}
          />
          <Route
            path="/kicking-complex-problems-to-the-curb-with-simple-solutions-the-office-coffee-debacle"
            component={KickingComplexProblems}
          />
          <Route
            path="/the-curse-of-complexity-how-it's-killing-our-work-mojo"
            component={TheCurseofComplexity}
          />
          <Route
            path="/navigating-workplace-complexity-a-survival-guide"
            component={NavigatingWorkplaceComplexity}
          />
          <Route
            path="/agile-vs-real-agility-unmasking-the-superhero-of-simplicity"
            component={AgilevsRealAgility}
          />

          <Route path={`/login`} component={Login} />

          {/* page not found */}
          <Route path={`*`} component={PageNotFound} />
        </Switch>
      </Layout>
    );
  }
}

export default InnerRoutes;
