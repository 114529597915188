import { Col, Row } from "antd";
import PeopleFirst from "../../assets/images/guiding-principles/peoplefirst.svg";
import simpleisPowerful from "../../assets/images/guiding-principles/simple-is-powerful.svg";
import commonSense from "../../assets/images/guiding-principles/common-sense.svg";
import blueWave from "../../assets/images/shapes/wave-blue.svg";

const GuidingPrincipleList = [
  {
    title: "People first, no matter what!",
    image: [PeopleFirst],
  },
  {
    title: "Simple is Powerful",
    image: [simpleisPowerful],
  },
  {
    title: "Common sense - saves the day!",
    image: [commonSense],
  },
];

export default function GuidingPrinciple() {
  return (
    <section className="guiding-principle spacing position-relative">
      <div className="container position-relative z-index">
        <h3 className="text-center mb-4">Our Guiding principles</h3>
        <Row align="center" justify="middle" gutter={[12, 24]}>
          {GuidingPrincipleList.map((list, i) => (
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 7 }}
              lg={{ span: 7 }}
              data-aos="fade-up"
              key={i}
              className="text-center"
            >
              <img
                src={list.image}
                alt={list.title}
                className="guidelines mb-2"
              />
              <p className="mb-0">{list.title}</p>
            </Col>
          ))}
        </Row>
      </div>
      <img
        src={blueWave}
        data-aos="fade-up"
        className="position-absolute right-0 bottom-0 w-100 z-index0"
      />
    </section>
  );
}
