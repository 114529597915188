import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import { Button, Col, message, Row, Steps } from "antd";
import { ArrowRight, ArrowLeft, TickCircle } from "iconsax-react";
import AssetPath from "../../AssetPath/AssetPath";
import "../Contact/Contact.scss";
import apiClient from "../../Actions/apiClient";

export default function ServiceSelectionForm() {
  const steps = [
    {
      title: "Step 1:",
      description: "Problem Statement",
    },
    {
      title: "Step 2:",
      description: "Expertise",
    },
    {
      title: "Step 3:",
      description: "Ways of Working",
    },
    {
      title: "Step 4:",
      description: "Business Details",
    },
  ];

  // service selection
  const servicesList = [
    {
      title: "Ways of Working",
      image: AssetPath.images.waysofWorking,
      link: "/ways-of-working",
      color: "var(--primary)",
      class: "bg1",
    },
    {
      title: "Ideas & Innovation",
      image: AssetPath.images.ideasInnovation,
      link: "/ideas-and-innovation",
      color: "var(--secondary)",
      class: "bg2",
    },
    {
      title: "Initiatives",
      image: AssetPath.images.initiatives,
      link: "/initiatives",
      color: "var(--purple)",
      class: "bg3",
    },
    {
      title: "Value & Impact Creation",
      image: AssetPath.images.valuesandImpactCreation,
      link: "/values-and-impact-creation",
      color: "var(--green)",
      class: "bg4",
    },
  ];

  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    description: item.description,
  }));

  // ways of working
  const waysofWokring = [
    {
      title: "Agility Booster",
      icon: AssetPath.icon.agilityBooster,
    },
    {
      title: "Culture",
      icon: AssetPath.icon.Culture,
    },
    {
      title: "Mindset",
      icon: AssetPath.icon.mindset,
    },
    {
      title: "Leadership Development",
      icon: AssetPath.icon.leadershipDevelopment,
    },
  ];

  // level
  const levels = [
    {
      title: "Individual",
      icon: AssetPath.icon.individual,
    },
    {
      title: "Team",
      icon: AssetPath.icon.team,
    },
    {
      title: "Organization",
      icon: AssetPath.icon.organization,
    },
  ];

  // level
  const interestedIn = [
    {
      title: "90 Days Experiment",
      icon: AssetPath.icon.ninetyDaysexperiments,
    },
    {
      title: "Consulting",
      icon: AssetPath.icon.consultingImg,
    },
  ];

  return (
    <div className="contact-form">
      <Formik
        initialValues={{
          problemStatement: "",
          futureState: "",
          fullName: "",
          teamsize: "",
          company: "",
          phone: "",
          location: "",
          message: "",
          emailAddress: "",
        }}
        //  onSubmit={(values) => console.log(values)}
        onSubmit={(values) => {
          apiClient.post("service_selection/add", values).then(({ data }) => {
            if (data && data.result) {
              message.success("Processing complete!");
            }
          });
        }}
      >
        <Form>
          <Steps current={current} items={items} />
          {current === 0 && (
            <div className="problem-statement pt-4">
              <Field
                name="problemStatement"
                as="textarea"
                required
                label="Describe the problem statement or opportunity?"
                placeholder="Enter your  Problem Statement.."
                type="text"
              />
              <Field
                name="futureState"
                as="textarea"
                required
                label="What is your desired future state?"
                placeholder="Enter your  desired future state"
                type="email"
              />
            </div>
          )}
          {/* //------------------------------------ */}
          {current === 1 && (
            <Field name="expertise">
              {({
                field, // { name, value, onChange, onBlur }
                form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc
              }) => (
                <Row
                  gutter={[
                    { xs: 10, sm: 16, md: 24, lg: 24 },
                    { xs: 10, sm: 16, md: 24, lg: 24 },
                  ]}
                  justify="center"
                  align="middle"
                  className="mt-4"
                >
                  {servicesList.map((data, i) => (
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 6 }}
                      lg={{ span: 6 }}
                      key={i}
                      className={`selection-card ${
                        field.value === data.title ? "active" : ""
                      }`}
                      onClick={() => {
                        setFieldValue("expertise", data.title);
                      }}
                    >
                      <div
                        className={`text-black selection-services-list ${data.class}`}
                        data-aos="fade-up"
                      >
                        <h4 className="text-start">{data.title}</h4>
                        <img src={data.image} alt={data.title} />
                        <div className="d-flex align-items-center justify-content-between">
                          <div
                            className="count"
                            style={{ WebkitTextStrokeColor: data.color }}
                          >
                            0{i + 1}
                          </div>
                        </div>
                        <div className="selected">
                          <TickCircle size={30} color="#fff" />
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
            </Field>
          )}
          {/* -- */}
          {/* {current === 2 && (
            <div className="wayof-working-selection text-center pt-4">
              <h4 className="text-center">Ways of working</h4>
              <p>Please choose an option</p>
              <Row
                gutter={[
                  { xs: 10, sm: 16, md: 24, lg: 24 },
                  { xs: 10, sm: 16, md: 24, lg: 24 },
                ]}
                justify="center"
                align="middle"
                className="mt-4"
              >
                {waysofWokring.map((data, i) => (
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 6 }}
                    lg={{ span: 6 }}
                    key={i}
                  >
                    <div className="working-options min-height">
                      <img src={data.icon} />
                      <label className="mb-0">{data.title}</label>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          )} */}

          {current === 2 && (
            <div className="wayof-working-selection text-center pt-4">
              <h4 className="text-center">Ways of working</h4>
              <p>Please choose an option</p>

              <Field name="waysOfWorking">
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc
                }) => (
                  <Row
                    gutter={[
                      { xs: 10, sm: 16, md: 24, lg: 24 },
                      { xs: 10, sm: 16, md: 24, lg: 24 },
                    ]}
                    justify="center"
                    align="middle"
                    className="mt-4"
                  >
                    {waysofWokring.map((data, i) => (
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}
                        key={i}
                        className={`selection-card ${
                          field.value === data.title ? "active" : ""
                        }`}
                        onClick={() => {
                          setFieldValue("waysOfWorking", data.title);
                        }}
                      >
                        {/* <div className="working-options min-height">
                      <img src={data.icon} />
                      <label className="mb-0">{data.title}</label>
                    </div> */}
                        <div
                          className="working-options min-height"
                          data-aos="fade-up"
                        >
                          <h4 className="text-start">{data.title}</h4>
                          <img src={data.icon} alt={data.title} />
                          <div className="d-flex align-items-center justify-content-between">
                            <div
                              className="count"
                              style={{ WebkitTextStrokeColor: data.color }}
                            >
                              0{i + 1}
                            </div>
                          </div>
                          <div className="selected">
                            <TickCircle size={30} color="#fff" />
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                )}
              </Field>
            </div>
          )}
          {/* ----------------------------------------------------------------------------------------------- */}
          {current === 3 && (
            <div className="business-details pt-4">
              <Row
                gutter={[
                  { xs: 10, sm: 10, md: 10, lg: 10 },
                  { xs: 10, sm: 10, md: 10, lg: 10 },
                ]}
                justify="center"
                align="middle"
                className="mt-4"
              >
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <h5 className="w-100 text-start  mb-2">Full Name</h5>
                  <Field
                    label={"Full name"}
                    name="fullName"
                    placeholder="Enter your team size.."
                    type="text"
                  />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <h5 className="w-100 text-start  mb-2">Team Size</h5>

                  <Field
                    label={"Team Size"}
                    name="teamsize"
                    placeholder="Enter your team size.."
                    type="text"
                  />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  className="mb-3"
                >
                  <h5 className="w-100 text-start  mb-2">Level</h5>
                  <p className="text-start mb-2">Please choose an option</p>
                  <div className="d-flex flex-column flex-md-row flex-lg-row align-items-center">
                    {levels.map((data, i) => (
                      <div className="choose-level-intrest" key={i}>
                        <img src={data.icon} />
                        <label className="mb-0">{data.title}</label>
                      </div>
                    ))}
                  </div>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  className="mb-3"
                >
                  <h5 className="w-100 text-start  mb-2">Interested In</h5>
                  <p className="text-start mb-2">Please choose an option</p>
                  <div className="d-flex flex-column flex-md-row flex-lg-row align-items-center">
                    {interestedIn.map((data, i) => (
                      <div className="choose-level-intrest" key={i}>
                        <img src={data.icon} />
                        <label className="mb-0">{data.title}</label>
                      </div>
                    ))}
                  </div>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <h5 className="w-100 text-start  mb-2">Company</h5>

                  <Field name="company" placeholder="Company Name (Optional)" />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <h5 className="w-100 text-start  mb-2">Email Address</h5>

                  <Field
                    name="emailAddress"
                    placeholder="Enter your email address.."
                    type="text"
                  />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <h5 className="w-100 text-start  mb-2">Phone Number</h5>

                  <Field
                    name="phone"
                    placeholder="Phone Number (Optional)"
                    type="phone"
                  />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <h5 className="w-100 text-start  mb-2">Location</h5>

                  <Field
                    name="location"
                    placeholder="Enter your location.."
                    type="text"
                  />
                </Col>
              </Row>
            </div>
          )}
          <div style={{ marginTop: 24 }}>
            {current > 0 && (
              <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                <ArrowLeft size={17} className="me-2" /> Previous
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next <ArrowRight size={17} className="ms-2" />
              </Button>
            )}

            {current === steps.length - 1 && (
              <>
                <Button htmlType="submit" type="primary">
                  Submit <ArrowRight size={17} className="ms-2" />
                </Button>

                {/* <Formik onSubmit={() => {
      apiClient.post('service_selection/add')
        .then(({ data }) => {
          if (data && data.result) {
            message.success("Processing complete!");
          }
        });
    }} /> */}
              </>
            )}
          </div>
        </Form>
      </Formik>
    </div>
  );
}
