import { ArrowRight } from "iconsax-react";
import { Link } from "react-router-dom";
import PostData from "./PostData";
import { Col, Row } from "antd";
import CalltoAction from "../Services/CalltoAction/CalltoAction";
import TitleBox from "../../Components/TitleBox/TitleBox";
import NewsletterSubscription from "../SubscribeNewsletter/NewsletterSubscription";

export default function Blogs() {
  return (
    <>
      <TitleBox title="Our Blogs" align="center" />
      <section className="our-blogs recent-post spacing bg-white">
        <div className="container">
          <Row
            align="center"
            justify="middle"
            gutter={[
              { xs: 0, sm: 16, md: 16, lg: 16 },
              { xs: 0, sm: 16, md: 16, lg: 16 },
            ]}
          >
            {PostData.map((post, i) => (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                key={i}
              >
                <Link
                  to={post.link}
                  className="font-4 text-primary more-details"
                  data-aos="fade-up"
                >
                  <div className="post-details bg-white">
                    <img
                      src={post.thumbnail}
                      alt={post.title}
                      className="w-100"
                    />
                    <div className="details">
                      <h4 className="text-black">{post.title}</h4>
                      <p className="text-gray font-4 text-ellipsis3">
                        {post.shortDesc}
                      </p>
                      <Link
                        to={post.link}
                        className="font-4 text-primary more-details"
                      >
                        More Details <ArrowRight />
                      </Link>
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      </section>
      <CalltoAction
        heading="simplify ways of working?"
        subheading="Start your 90 days experiment."
        color="black"
        themeColor="bg2 "
        spacing="spacing"
      />
    </>
  );
}
