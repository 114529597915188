import circleTransparent from "../assets/images/shapes/circle-light-yellow.svg";
import SimpleIdeasGmbhServices from "../assets/images/simple-ideas-gmbh-services.svg";
import servicesImg from "../assets/images/services/services.svg";
import waysofWorking from "../assets/images/services/services-page/ways-of-working.svg";
import initiatives from "../assets/images/services/initiatives.svg";
import ideasInnovation from "../assets/images/services/ideas-and-innovation.svg";
import valuesandImpactCreation from "../assets/images/services/services-page/values-and-inpact-creation.svg";
import weDeliverServices from "../assets/images/services/we-deliver-services.svg";
import circle from "../assets/images/services/circle.svg";
import cta from "../assets/images/cta.png";
import GetUpdate from "../assets/images/get-updates.svg";
import whatToExpect from "../assets/images/what-to-expact.svg";

// shapes
import outlineYellowCircle from "../assets/images/shapes/outlineYellowCircle.svg";
import waveLineYellow from "../assets/images/shapes/wave-line-yellow.svg";
import glitterWhite from "../assets/images/shapes/glitter-white.svg";
import glitterBlue from "../assets/images/shapes/glitter-blue.svg";
import glitterYellow from "../assets/images/shapes/glitter-yellow.svg";
import BlueCircle from "../assets/images/shapes/blue-circle.svg";
import zigzagShape from "../assets/images/shapes/zigzag-shape.svg";
import contactShape from "../assets/images/shapes/contact-shape.svg";
import circleYellow from "../assets/images/shapes/circle-yellow.svg";

// experiment
import experiment from "../assets/images/services/trial.png";
import consulting from "../assets/images/services/consulting.png";
import helpingHands from "../assets/images/services/helping-hands.png";
import learnaThrons from "../assets/images/services/learn-a-throns.png";

// icons
import Culture from "../assets/images/icons/culture.png";
import agilityBooster from "../assets/images/icons/agility-booster.png";
import mindset from "../assets/images/icons/mindset.png";
import leadershipDevelopment from "../assets/images/icons/leadership-development.png";
import individual from "../assets/images/icons/individual.png";
import team from "../assets/images/icons/team.png";
import organization from "../assets/images/icons/organization.png";
import ninetyDaysexperiments from "../assets/images/icons/free-trial.png";
import consultingImg from "../assets/images/icons/consulting.png";

// brand
import simpleisPowerfulWhite from "../assets/images/simple-is-powerful-white.svg";
import simpleisPowerfulDark from "../assets/images/simple-is-powerful.svg";

// blogs
import keepitSimple from "../assets/images/blogs/keep-it-simple-silly.svg";
import unleashthePower from "../assets/images/blogs/unleash-the-power-of-simplicity.svg";
import kickingComplexProblems from "../assets/images/blogs/kicking-complex-problem-to-the-curb-with-simple-solution.svg";
import workplaceComplexity from "../assets/images/blogs/workplace-complexity.svg";
import workMojo from "../assets/images/blogs/work-mojo.svg";
import superHero from "../assets/images/blogs/superhero.svg";

// divider
import circleDivider from "../assets/images/divider/white-divider.png";
import circleDividerBottom from "../assets/images/divider/white-divider-bottom.png";
import cardDesign from "../assets/images/divider/card-design.png";

// contact
import KnowMoreBtn from "../assets/images/know-more.svg";
import JoinOurTeam from "../assets/images/contact-us/work-with-us.svg";
import becomePartner from "../assets/images/contact-us/become-a-partner.svg";

// about image
import AboutImg from "../assets/images/about-us/about.png";
import whysimpleIdeas from "../assets/images/about-us/why-simple-ideas.svg";

// services
import waysofWorkingImage from "../assets/images/services/ways-of-working.svg";
import ideasandInnovationImage from "../assets/images/services/ideas-and-innovation.svg";
import initiativesImage from "../assets/images/services/initiatives.svg";
import valuesandImpact from "../assets/images/services/value-and-impact-creation.svg";

import waysofWorkingBanner from "../assets/images/services/ways-of-working-banner.svg";
import ideasandInnovationBanner from "../assets/images/services/ideas-and-innovation-banner.svg";
import initiativesBanner from "../assets/images/services/initiatives-banner.svg";
import valuesandImpactBanner from "../assets/images/services/value-and-impact-creation-banner.svg";

const AssetPath = {
  brand: {
    simpleisPowerfulWhite,
    simpleisPowerfulDark,
  },
  icon: {
    experiment,
    consulting,
    helpingHands,
    learnaThrons,
    mindset,
    Culture,
    agilityBooster,
    leadershipDevelopment,
    individual,
    team,
    consultingImg,
    ninetyDaysexperiments,
    organization,
  },
  images: {
    SimpleIdeasGmbhServices,
    GetUpdate,
    servicesImg,
    waysofWorking,
    initiatives,
    ideasInnovation,
    valuesandImpactCreation,
    weDeliverServices,
    cta,
    whatToExpect,
  },
  shapes: {
    circleTransparent,
    circle,
    circleYellow,
    outlineYellowCircle,
    waveLineYellow,
    glitterWhite,
    glitterBlue,
    glitterYellow,
    BlueCircle,
    zigzagShape,
    contactShape,
  },
  flags: {},

  blogs: {
    keepitSimple,
    unleashthePower,
    kickingComplexProblems,
    workplaceComplexity,
    workMojo,
    superHero,
  },
  divider: {
    circleDivider,
    circleDividerBottom,
    cardDesign,
  },
  contact: {
    KnowMoreBtn,
    JoinOurTeam,
    becomePartner,
  },
  about: {
    AboutImg,
    whysimpleIdeas,
  },
  services: {
    waysofWorkingImage,
    ideasandInnovationImage,
    initiativesImage,
    valuesandImpact,
    waysofWorkingBanner,
    ideasandInnovationBanner,
    initiativesBanner,
    valuesandImpactBanner,
  },
};

export default AssetPath;
