import "./TitleBox.scss";
import halfCircleRight from "../../assets/images/shapes/half-circle-right.svg";

export default function TitleBox({ title, align }) {
  return (
    <section className="title-box bg-yellow-lineargradient position-relative">
      <img
        src={halfCircleRight}
        className="circle-half position-absolute"
        alt="SimpleIdeas GmbH"
      />
      <div className="container">
        <div class="highlight-title" data-aos="fade-right">
          <h1
            className={`text-black ${
              align === "center" ? "text-center" : "text-start"
            }`}
          >
            {title}
          </h1>
        </div>
      </div>
    </section>
  );
}
