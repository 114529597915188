import ServiceTitleSection from "./ServiceTitleSection";
import ServiceTitleSectionData from "./ServiceTitleSectionData";
import CalltoAction from "./CalltoAction/CalltoAction";
import ideasofAction from "../../assets/images/services/ideas-and-innovation/ideas-of-action.svg";
import productsExperiencesDesign from "../../assets/images/services/ideas-and-innovation/products-experiences-design.svg";
import rapidPrototyping from "../../assets/images/services/ideas-and-innovation/rapid-prototyping.svg";
import SectionBox from "../../Components/SectionBox/SectionBox";
import RelatedServices from "./RelatedServices";

const sectionsData = [
  {
    align: "normal",
    title: "Ideas to Action",
    desc: [
      "Don't be stuck in overthinking or blind action. Strike a balance between thinking and doing. The best ideas often stem from needs and imagination. Thinking beyond boundaries sparks innovation, while a practical approach brings those ideas to life.",
      "Our special blend of delusional and intentional thinking sparks ideas and ignites action. master this dynamic duo for idea-to-value transformation!",
    ],
    image: ideasofAction,
  },
  {
    title: "Products/Services/Experiences Design",
    desc: [
      "There's a solution for every problem, but its success hinges on one critical factor: does it genuinely solve someone's problem?",
      "Discover how to create products, services, or experiences the simple way—by keeping the user at the heart of the equation and simplicity as your guiding principle",
    ],
    image: productsExperiencesDesign,
  },
  {
    align: "normal",
    title: "Rapid Prototyping & Development",
    desc: [
      "Got an idea but not sure where to begin? Lacking the resources or means to bring it to life?",
      "Collaborate with us to turn your ideas into reality. Our expert team/ partners will join forces with you to swiftly craft prototypes.",
    ],
    image: rapidPrototyping,
  },
];

const sectionComponents = sectionsData.map((data, index) => (
  <SectionBox
    key={index}
    align={data.align}
    title={data.title}
    desc={data.desc}
    image={data.image}
  />
));

export default function ideasandInnovation() {
  const ideasandInnovationData =
    ServiceTitleSectionData.pages[0].ideasandInnovation;
  return (
    <>
      <ServiceTitleSection
        title={ideasandInnovationData.title}
        ShortDesc={ideasandInnovationData.shortDesc}
        DetailedDesc={ideasandInnovationData.DetailedDesc}
        pageLink={ideasandInnovationData.link}
        image={ideasandInnovationData.image}
        themeColor={ideasandInnovationData.themeColor}
      />
      <div className="shape-blue-gradient shape-yellow-gradient">
        {sectionComponents}
      </div>
      <RelatedServices />

      <CalltoAction
        heading="Make it happen?"
        subheading="Start your 90 days experiment"
        color="black"
        themeColor="bg2"
      />
    </>
  );
}
