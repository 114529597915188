import { Col, Row } from "antd";
import "./SectionBox.scss";

export default function SectionBox({ image, title, desc, align, bgColor }) {
  return (
    <section className={`section-box spacing ${bgColor}`}>
      <div className="container">
        <Row
          gutter={[
            { xs: 0, sm: 16, md: 24, lg: 40 },
            { xs: 0, sm: 16, md: 24, lg: 40 },
          ]}
          className=""
          justify="center"
          align="middle"
        >
          <Col
            xs={{
              span: 24,
              ...(align === "normal" ? { order: 1 } : { order: 1 }),
            }}
            sm={{
              span: 24,
              ...(align === "normal" ? { order: 1 } : { order: 1 }),
            }}
            md={{
              span: 10,
              ...(align === "normal" ? { order: 2 } : { order: 1 }),
            }}
            lg={{
              span: 10,
              ...(align === "normal" ? { order: 2 } : { order: 1 }),
            }}
          >
            <div className="text-center" data-aos="fade-up">
              <img src={image} alt={title} />
            </div>
          </Col>
          <Col
            xs={{
              span: 24,
              ...(align === "normal" ? { order: 2 } : { order: 2 }),
            }}
            sm={{
              span: 24,
              ...(align === "normal" ? { order: 2 } : { order: 2 }),
            }}
            md={{
              span: 10,
              ...(align === "normal" ? { order: 1 } : { order: 2 }),
            }}
            lg={{
              span: 10,
              ...(align === "normal" ? { order: 1 } : { order: 2 }),
            }}
          >
            <div data-aos="fade-up">
              <h3 className="mb-2">{title}</h3>
              {Array.isArray(desc) ? (
                desc.map((item, index) => <p key={index}>{item}</p>)
              ) : (
                <p>{desc}</p>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}
