import React from "react";
import { Col, Row, Tooltip } from "antd";
import "./AboutUs.scss";
import ButtonBox from "../../Components/ButtonBox/ButtonBox";
import AboutImg from "../../assets/images/about-us/about.png";
import { Link } from "react-router-dom";
import { Facebook, Instagram, Linkedin, Twitter } from "feather-icons-react";

import dividedCircle from "../../assets/images/shapes/splitted-circle.svg";

import aaaLogo from "../../assets/images/partners/gray/aaa.png";
import genfuturelabLogo from "../../assets/images/partners/gray/genfuture-lab.png";
import mcbitssLogo from "../../assets/images/partners/gray/mcbitss.png";
import shareBayLogo from "../../assets/images/partners/gray/sharebay.png";
import circleYellow from "../../assets/images/shapes/circle-yellow.svg";

import glitterYellow from "../../assets/images/shapes/glitter-yellow.svg";
import glitterBlue from "../../assets/images/shapes/glitter-blue.svg";
import PrathNewnham from "../../assets/images/about-us/prath-newnham-simple-ideas.png";
import NewsletterSubscription from "../SubscribeNewsletter/NewsletterSubscription";
import AssetPath from "../../AssetPath/AssetPath";

const PartnersLogo = [
  {
    name: "aaa.ai",
    image: [aaaLogo],
    website: "https://aaa.ai",
  },
  {
    name: "Gen Futures Lab",
    image: [genfuturelabLogo],
    website: "https://genfutures.co.uk/",
  },
  {
    name: "Mcbitss GmbH",
    image: [mcbitssLogo],
    website: "https://mcbitss.com",
  },
  {
    name: "ShareBay",
    image: [shareBayLogo],
    website: "https://sharebay.org",
  },
];

const followUs = [
  {
    title: "Linkedin",
    link: "https://www.linkedin.com",
    icon: <Linkedin />,
  },
  {
    title: "Twitter",
    link: "https://www.twitter.com",
    icon: <Twitter />,
  },
  {
    title: "Facebook",
    link: "https://www.facebook.com",
    icon: <Facebook />,
  },

  {
    title: "Instagram",
    link: "https://www.instagram.com",
    icon: <Instagram />,
  },
];

export default function AboutUs() {
  return (
    <React.Fragment>
      <section className="about-us bg-white min-height overflow-hidden">
        <img
          src={circleYellow}
          alt="Simple Ideas GmbH"
          className="about-circle"
          data-aos="fade-up"
        />
        <div className="container position-relative z-index">
          <Row
            align="center"
            justify="middle"
            gutter={[
              { xs: 0, sm: 12, md: 25, lg: 30 },
              { xs: 0, sm: 12, md: 25, lg: 30 },
            ]}
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
            >
              <h1 className="mb-2 font-2 text-uppercase" data-aos="fade-up">
                About Us{" "}
                <img
                  src={glitterBlue}
                  width="50px"
                  alt="About Simple Ideas GmbH"
                />
              </h1>
              <h4 className="h2">We are Simple People doing great things!</h4>
              <p className="text-gray">
                We are a group of like minded individuals who love to roll up
                their sleeves and get things done! We come from diverse
                backgrounds both culturally and professionally. Together we
                share one thing in common - make workplaces better, more
                meaningful!
              </p>
              <div className="d-flex">
                <ButtonBox
                  type="primary"
                  rightArrowIcon
                  label="Our Services"
                  className="me-2"
                />
                <ButtonBox type="text" label="The Simple Framework" />
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <img
                src={AboutImg}
                className="image"
                alt="About Simple Ideas GmbH"
                data-aos="fade-up"
              />
            </Col>
          </Row>
        </div>
      </section>
      <div className="shape-about-gradient">
        <section className="our-partners spacing position-relative z-index">
          <div className="container text-center">
            <h3 className="mb-3" data-aos="fade-up">
              Our Partners
            </h3>
            <div data-aos="fade-up">
              {PartnersLogo.map((logo, i) => (
                <Tooltip title={logo.name} color="#00cbbf" key={i}>
                  <a href={logo.website} target="_blank" rel="noreferrer">
                    <img src={logo.image} alt={logo.name} />
                  </a>
                </Tooltip>
              ))}
            </div>
          </div>
        </section>
        <section className="meet-simplifier spacing position-relative z-index">
          <div className="container">
            <div className="mb-4 text-center">
              <h3 className="mb-2">Meet our Simplifiers</h3>
              <p>
                <span className="text-primary">We are hiring!</span> if you have
                a <span className="text-primary">“simple mindset”</span> and you
                would like to join our team{" "}
                <Link
                  to="/contact-us"
                  className="text-secondary text-decoration-underline"
                >
                  get in touch
                </Link>
              </p>
            </div>
            <Row align="center" justify="middle">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <div
                  className="bg9  prath-profile border-radius"
                  data-aos="fade-up"
                >
                  <Row
                    align="center"
                    gutter={[
                      { xs: 0, sm: 12, md: 30, lg: 40 },
                      { xs: 0, sm: 12, md: 30, lg: 40 },
                    ]}
                  >
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}
                    >
                      <img
                        src={PrathNewnham}
                        alt="Prath Newnham Simple Ideas GmbH"
                        className="prath w-100 border-radius"
                        data-aos="fade-up"
                      />
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 14 }}
                      lg={{ span: 14 }}
                    >
                      <div data-aos="fade-up">
                        <h4 className="h3 mb-3  text-white">
                          Prath Newnham{" "}
                          <img
                            src={glitterYellow}
                            width="50px"
                            alt="About Simple Ideas GmbH"
                          />
                        </h4>
                        <p className="text-white">
                          "Meet Prath, a seasoned leader with over a decade of
                          experience driving global initiatives in banking and
                          life sciences across the UK and Switzerland. Her
                          expertise lies in simplifying complex matters, from
                          reshaping organizational structures and operating
                          models to implementing new Ways of Working and
                          navigating cutting-edge technologies like AI
                        </p>
                        <p className="text-white">
                          <strong>Prath's superpower?</strong> Leading diverse
                          groups through change. She strongly believes in the
                          power of individuals and collective strength. Calling
                          Asia, Africa, and Europe home, Prath brings her unique
                          cultural experiences to foster borderless team
                          cohesion. As an artist (painter), she seamlessly
                          integrates creativity into daily practices.
                        </p>
                        <p className="text-white">
                          <strong>Her mission?</strong> To kickstart a workplace
                          simplicity revolution. In her words, 'Simplicity has
                          the power to turn tough challenges into real
                          successes.' With a proven track record of leading
                          successful initiatives and bringing numerous ideas to
                          life, Prath is dedicated to sparking ideas and actions
                          for individuals, teams, and organizations, making the
                          future of work more creative, action oriented and
                          meaningful."
                        </p>
                        <div className="name-role text-white">
                          <span className="d-block mb-2  text-secondary font-2 font-weight-semibold">
                            Chief Simplicity Officer
                          </span>
                          <span className="d-block mb-2  text-white font-4">
                            Simple Ideas GmbH
                          </span>
                        </div>
                        <div className="social-media  text-white">
                          <ul className="list-unstyled p-0 m-0 d-flex">
                            {followUs.map((socialLink, index) => (
                              <li key={index}>
                                <a
                                  href={socialLink.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-white p-2 "
                                >
                                  {socialLink.icon}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </div>

      <section className="why-simple-ideas position-relative spacing section-top3">
        <div className="container">
          <Row
            align="center"
            justify="middle"
            gutter={[
              { xs: 0, sm: 12, md: 25, lg: 30 },
              { xs: 0, sm: 12, md: 25, lg: 30 },
            ]}
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <img
                src={dividedCircle}
                className="position-absolute circle"
                alt="Simple Ideas GmbH"
                data-aos="fade-up"
              />
              <div className="content" data-aos="fade-up">
                <h3 className="h2 mb-2">Why Simple Ideas</h3>
                <p>
                  Because we wont waste your time with hundreds of slides or
                  endless talking. We will get straight to the point and lend
                  you a hand in making your ideas and initiatives successful. No
                  jargons, no bla bla, Real value delivered simply!
                </p>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <div className="text-center" data-aos="fade-up">
                <img
                  src={AssetPath.about.whysimpleIdeas}
                  className="image"
                  alt="Why Simple Ideas GmbH"
                />
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <NewsletterSubscription />
    </React.Fragment>
  );
}
