import { Button, Col, Row } from "antd";
import React from "react";
import "./Contact.scss";
import ContactForm from "./ContactForm";
import { ArrowRight, CallCalling, Message } from "iconsax-react";
import { Mail, ArrowUpRight } from "feather-icons-react";
import NewsletterSubscription from "../SubscribeNewsletter/NewsletterSubscription";

import { useHistory } from "react-router-dom";
import AssetPath from "../../AssetPath/AssetPath";

export default function Contact() {
  const history = useHistory();
  const contactWays = [
    {
      title: "Book free consultation",
      icon: <Message />,
      desc: "Speak to our friendly team",
      linkText: "Book now",
      link: "https://calendly.com/prath-newnham/free-consultation",
      class: "bg-white text-black",
    },
    {
      title: "Email us",
      icon: <Mail />,
      desc: "we’re here to help",
      linkText: "info@simpleideas.ch",
      link: "mailto:info@simpleideas.ch",
      class: "bg-primary text-white",
    },
    {
      title: "Call Us",
      icon: <CallCalling />,
      desc: "Mon to Fri from 9am to 5pm",
      linkText: "+41 78 649 15 41",
      link: "tel:+41 78 649 15 41",
      class: "bg-white  text-black",
    },
  ];

  const PartnerSection = [
    {
      title: "Become a partner",
      desc: "Join us, become a valued partner in our journey towards shared success",
      altTitle: "Become a partner with SimpleIdeas GmbH",
      image: AssetPath.contact.becomePartner,
      linkText: "Apply for Partnership",
      Link: "/become-a-partner",
      class: "bg1",
    },
    {
      title: "Join our team",
      desc: "Join our dynamic team, where innovation thrives and collaboration leads to success.",
      altTitle: "Join our team with SimpleIdeas GmbH",
      image: AssetPath.contact.JoinOurTeam,
      linkText: "Apply Now",
      Link: "/career",
      class: "bg2",
    },
  ];

  return (
    <React.Fragment>
      <section className="viewport-height main-page d-flex  align-items-center justify-content-center contact-title-section bg-white spacing bg-yellow-purple-gradient position-relative">
        <div className="overflow-hidden w-100">
          <img
            src={AssetPath.shapes.contactShape}
            alt="SimpleIdeas GmbH"
            className="position-absolute  semicircle "
          />
          <div className="container">
            <div class="highlight-title mt-4 text-center" data-aos="fade-up">
              <span className="h3 d-block text-white">
                Contact Us
                <img
                  src={AssetPath.shapes.glitterWhite}
                  width="50px"
                  alt="Contact Simple Ideas GmbH"
                />
              </span>
              <h3 className="h1">
                Get in touch with us for{" "}
                <span className="d-block">more information</span>
              </h3>
              <p className="lead text-black mt-3">
                if you need a help or have question, we’re here for you.
              </p>
            </div>

            <div className="contact-ways">
              <Row
                gutter={[
                  { xs: 10, sm: 16, md: 24, lg: 32 },
                  { xs: 10, sm: 16, md: 24, lg: 20 },
                ]}
                className="pb-3 "
                align="middle"
                justify="center"
              >
                {contactWays.map((data, i) => (
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 7 }}
                    lg={{ span: 7 }}
                    key={i}
                  >
                    <a
                      href={data.link}
                      target="_blank"
                      rel="noreferrer"
                      data-aos="fade-up"
                    >
                      <div
                        className={`details position-relative ${data.class}`}
                      >
                        <ArrowUpRight className="position-absolute" />
                        <span className="icon">{data.icon}</span>
                        <h3 className="mb-0">{data.title}</h3>
                        <p className="mb-2 font-4">{data.desc}</p>
                        <a
                          href={data.link}
                          target="_blank"
                          rel="noreferrer"
                          className="text-decoration-underline"
                        >
                          {data.linkText}
                        </a>
                      </div>
                    </a>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </div>

        <div className="position-absolute start-0 bottom-0">
          <img
            src={AssetPath.divider.circleDivider}
            className="w-100"
            alt="SimpleIdeas GmbH"
          />
        </div>
        <div className="position-absolute know-more-btn">
          <img src={AssetPath.contact.KnowMoreBtn} alt="SimpleIdeas GmbH" />
        </div>
      </section>
      <section className="contact-support spacing light-background position-relative">
        <div className="container">
          <img
            src={AssetPath.shapes.zigzagShape}
            className="position-absolute zigzag-shape"
            alt="SimpleIdeas GmbH"
          />
          <Row
            gutter={[
              { xs: 10, sm: 16, md: 24, lg: 32 },
              { xs: 10, sm: 16, md: 24, lg: 20 },
            ]}
            className="pb-3 "
            align="middle"
            justify="center"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <div class="highlight-title" data-aos="fade-up">
                <h3 className="text-center">
                  Just fill out the form and hit submit.
                </h3>
                <p className="text-center">we're here to assist</p>
              </div>
              <ContactForm />
            </Col>
          </Row>
          <img
            src={AssetPath.shapes.BlueCircle}
            className="position-absolute circle-blue-shape"
            alt="SimpleIdeas GmbH"
          />
        </div>
      </section>

      <section className="become-partner-team spacing-bottom">
        <div className="container">
          <Row
            gutter={[
              { xs: 10, sm: 16, md: 24, lg: 32 },
              { xs: 10, sm: 16, md: 24, lg: 20 },
            ]}
            align="middle"
            justify="center"
          >
            {PartnerSection.map((data, i) => (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 10 }}
                key={i}
              >
                <div className={`details ${data.class}`} data-aos="fade-up">
                  <div className="">
                    <h3 className="text-black">{data.title}</h3>
                    <p className="font-4 text-gray">{data.desc}</p>
                  </div>
                  <div className="">
                    <img src={data.image} alt={data.altTitle} />
                  </div>
                  <div className="d-block pt-3">
                    <Button
                      type="default"
                      color="primary"
                      to={data.Link}
                      onClick={() => history.push(`${data.Link}`)}
                      className="font-4"
                    >
                      {data.linkText}{" "}
                      <span className="ms-2">
                        <ArrowRight />
                      </span>
                    </Button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </section>
      <NewsletterSubscription />
    </React.Fragment>
  );
}
