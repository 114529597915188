import { Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import weSpecialized from "../../assets/images/we-specialized.svg";
import TitleBox from "../../Components/TitleBox/TitleBox";
import { ArrowRight } from "iconsax-react";

export default function PageNotFound() {
  return (
    <React.Fragment>
      <TitleBox title="Page Not Found" align={"center"} />
      <section className="faq spacing light-background">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            className="pb-3 "
            align="center"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 18 }}
              lg={{ span: 18 }}
              data-aos="fade-up"
            >
              <div class="highlight-title text-center" data-aos="fade-up">
                <img
                  src={weSpecialized}
                  alt="404 Error - Page Not Found"
                  className="w-100 mb-3"
                />
                <h1>404 Error - Page Not Found</h1>
                <p>
                  The page you are looking for could not be found. Please check
                  the URL or go back to the homepage.
                </p>
                <Link to="/" className="ant-btn ant-btn-primary">
                  Go back to Home
                  <span className="ms-1 arrow-right">
                    <ArrowRight />
                  </span>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
}
