import AssetPath from "../../AssetPath/AssetPath";

const RelatedServicesData = [
  {
    title: "Ways of Working ",
    shortDesc: "Revamp your operating model",
    DetailedDesc:
      "Say farewell to the old, complicated work styles - the processes, barriers, bureaucracy, and jargon. Say hi to simplicity, creativity, action, and impact. Ready to switch gears? Join us to transform your routine into something fun, meaningful, and impactful!",
    image: AssetPath.services.waysofWorkingBanner,
    link: "/ways-of-working",
  },
  {
    title: "Ideas & Innovation ",
    shortDesc: "Good ideas must be more than just thoughts.",
    DetailedDesc:
      "Complex solutions aren't always the answer. Discover how simplicity can drive your ideas from conception to action. Afterall Innovation is creating something new or improving existing things in a clever, better way.",
    image: AssetPath.services.ideasandInnovationBanner,
    link: "/ideas-and-innovation",
  },
  {
    title: "Initiatives",
    shortDesc: "Change / Transformation Projects / Programs.",
    DetailedDesc:
      "At the core of thriving organizations lies the power to take action. Any initiative, regardless of size, can succeed with practicality as its guiding principle. No need for fancy methodologies to build a doer's organization. Join us to discover fresh approaches to achieve initiative objectives meaningfully and unlock your action-oriented capabilities!",
    image: AssetPath.services.initiativesBanner,
    link: "/initiatives",
  },
  {
    title: "Value & Impact Creation",
    shortDesc: "Don’t leave your impact to chance",
    DetailedDesc:
      "Don't waste time and resources on efforts that don't produce real outcomes. Often, this crucial aspect is overlooked because value and impact come at the end of the execution cycle and are influenced by external factors. Our approach guides you on prioritizing impact and value, integrating them into your organizational culture.",
    extraText:
      "Strategies focused on impact are more potent than inward-facing ones. Join us in reshaping your perspective and instilling an impact-driven DNA in your organization.",
    image: AssetPath.services.valuesandImpactBanner,
    link: "/values-and-impact-creation",
  },
];

export default RelatedServicesData;
