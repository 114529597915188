import React, { useState } from "react";
import "./Header.scss"; // Ensure this path is correct for your project structure
import { Link } from "react-router-dom";
import { BackTop, Col, Row } from "antd";
import { ArrowUp2, Send2, Whatsapp } from "iconsax-react";
import "./Footer.scss"; // Ensure this path is correct for your project structure
import FooterLinks from "./FooterLinks"; // Ensure this import path is correct
import AssetPath from "../AssetPath/AssetPath"; // Ensure this import path is correct
import { ChevronDown } from "feather-icons-react";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const [openMenus, setOpenMenus] = useState({});

  const handleOpenMenu = (index) => {
    setOpenMenus((prevOpenMenus) => ({
      ...prevOpenMenus,
      [index]: !prevOpenMenus[index],
    }));
  };

  return (
    <React.Fragment>
      <section className="footer black-background spacing bg11">
        <div className="container">
          <div className="footer-links">
            <Row
              gutter={[
                { xs: 0, sm: 16, md: 24, lg: 32 },
                { xs: 0, sm: 16, md: 24, lg: 20 },
              ]}
            >
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                <div className="location-contact" data-aos="fade-up">
                  <h5>Simple Ideas GmbH</h5>
                  <p>Greater Zurich area, Switzerland</p>
                  <ul className="list-unstyled contact-details mb-3">
                    <li className="mb-3">
                      <a href="tel:+41 78 649 15 41" className="text-black">
                        <Whatsapp className="me-2" />
                        +41 78 649 15 41
                      </a>
                    </li>
                    <li>
                      <a
                        href="mailto:info@simpleideas.ch"
                        className="text-black"
                      >
                        <Send2 className="me-2" />
                        info@simpleideas.ch
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              {FooterLinks.map((data, i) => (
                <Col
                  key={i}
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: i === 0 || i === 1 ? 5 : 4 }}
                >
                  <div className="links" data-aos="fade-up">
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="main-title">{data.title}</h5>
                      <div
                        onClick={() => handleOpenMenu(i)}
                        className="collapsible"
                      >
                        <h5 className="collapsible-title">{data.title}</h5>
                        <ChevronDown
                          size={16}
                          className={`${openMenus[i] ? "rotate" : ""} `}
                        />
                      </div>
                    </div>
                    <ul
                      className={`p-0 m-0 ${openMenus[i] ? "show-links" : ""}`}
                    >
                      {data.links.map((list, i) => (
                        <li key={i}>
                          {list.icon ? (
                            <a
                              href={list.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {list.icon}
                              {list.title}
                            </a>
                          ) : (
                            <Link to={list.link}>{list.title}</Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <div className="copyright-content d-flex align-items-center justify-content-between">
            <div className="simple-powerful d-flex align-items-center">
              <img
                src={AssetPath.brand.simpleisPowerfulDark}
                alt="Simple Ideas GmbH | Simple is Powerful"
              />
              <span className="font-2 ms-2 font-weight-medium text-black">
                Simple Is Powerful
              </span>
            </div>

            <p className="mb-0 font-4 text-black">
              Copyright © {currentYear}{" "}
              <span className="text-primary">Simple Ideas GmbH</span>. All
              Rights Reserved.
            </p>
          </div>
        </div>
      </section>
      <BackTop>
        <div className="back-to-top">
          <ArrowUp2 size={15} />
        </div>
      </BackTop>
    </React.Fragment>
  );
}
