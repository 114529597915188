import { Col, Row } from "antd";
import halfCircleRight from "../../assets/images/shapes/half-circle-right.svg";
import "./ServiceSelection.scss";
import ServiceSelectionForm from "./ServiceSelectionForm";

export default function ServiceSelection() {
  return (
    <>
      <section className=" title-box bg-yellow-lineargradient position-relative">
        <div className="container text-center">
          <img
            src={halfCircleRight}
            className="circle-half position-absolute"
            alt="SimpleIdeas GmbH"
          />
          <Row
            gutter={[
              { xs: 0, sm: 12, md: 12, lg: 12 },
              { xs: 0, sm: 12, md: 12, lg: 12 },
            ]}
            justify="center"
            align="middle"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 16 }}
              lg={{ span: 12 }}
              data-aos="fade-up"
            >
              <h1 className="h2">Discover Your Ideal Service</h1>
              <p className="text-gray font-4">
                Answer a few questions and find the ideal solution that meets
                your needs and preferences effortlessly
              </p>
            </Col>
          </Row>
        </div>
      </section>
      <section className=" spacing-bottom mt-5">
        <div className="container text-center">
          <Row
            gutter={[
              { xs: 0, sm: 12, md: 12, lg: 12 },
              { xs: 0, sm: 12, md: 12, lg: 12 },
            ]}
            justify="center"
            align="middle"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 18 }}
              lg={{ span: 18 }}
              data-aos="fade-up"
            >
              <ServiceSelectionForm />
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
