import { useHistory } from "react-router-dom";
import { Col, Row, Tooltip } from "antd";
import ButtonBox from "../../Components/ButtonBox/ButtonBox";
import aaaLogo from "../../assets/images/partners/aaa.png";
import genfuturelabLogo from "../../assets/images/partners/gen-future-lab.png";
import mcbitssLogo from "../../assets/images/partners/mcbitss.png";
import shareBayLogo from "../../assets/images/partners/sharebay.png";
import Marquee from "react-fast-marquee";
import "./PartnersSection.scss";

const PartnersLogo = [
  {
    name: "aaa.ai",
    image: [aaaLogo],
    website: "https://aaa.ai",
  },
  {
    name: "Gen Futures Lab",
    image: [genfuturelabLogo],
    website: "https://genfutures.co.uk/",
  },
  {
    name: "Mcbitss GmbH",
    image: [mcbitssLogo],
    website: "https://mcbitss.com",
  },
  {
    name: "ShareBay",
    image: [shareBayLogo],
    website: "https://sharebay.org",
  },
  {
    name: "aaa.ai",
    image: [aaaLogo],
    website: "https://aaa.ai",
  },
  {
    name: "Gen Futures Lab",
    image: [genfuturelabLogo],
    website: "https://genfutures.co.uk/",
  },
  {
    name: "Mcbitss GmbH",
    image: [mcbitssLogo],
    website: "https://mcbitss.com",
  },
  {
    name: "ShareBay",
    image: [shareBayLogo],
    website: "https://sharebay.org",
  },
];

export default function PartnersSection() {
  const navigate = useHistory();

  return (
    <section className="partners-section spacing position-relative partners-bg overflow-hidden">
      <div className="container position-relative z-index text-center">
        <Row align="center" justify="middle" gutter={[12, 24]}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            data-aos="fade-up"
          >
            <h4 className="mb-2 text-primary font-weight-medium">
              Partners & Team
            </h4>
            <h3 className="mb-4 text-black">The collective force</h3>
            <p className="text-gray">
              We rely on a strong network of like minded individuals and
              organisations to deliver our diverse services globally
            </p>
          </Col>
        </Row>
        <div
          className="partners-logo d-flex my-3 align-items-center justify-content-center"
          data-aos="zoom-in-up"
        >
          <Row align="center" justify="middle" gutter={[12, 24]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 18 }}
              lg={{ span: 18 }}
            >
              <div className="partner-marquee">
                <Marquee
                  gradient="true"
                  gradientColor="#fff"
                  gradientWidth={50}
                  direction="left"
                  delay={0}
                >
                  {PartnersLogo.map((logo, i) => (
                    <a
                      key={i}
                      href={logo.website}
                      className="list-items"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={logo.image} alt={logo.name} />
                    </a>
                  ))}
                </Marquee>
              </div>
            </Col>
          </Row>
        </div>

        <h5 className="mt-4 mb-3">Join our Global partners network</h5>
        <ButtonBox
          onClick={() => navigate.push("/become-a-partner")}
          type="primary"
          rightArrowIcon
          label="Become a partner"
        />
      </div>
    </section>
  );
}
