import { Col, Row } from "antd";
import React from "react";
import TitleBox from "../../Components/TitleBox/TitleBox";

export default function Privacy() {
  return (
    <React.Fragment>
      <TitleBox title={"Privacy Policy"} align={"center"} />
      <section className="faq spacing light-background">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            className="pb-3 "
            align="center"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 18 }}
              lg={{ span: 18 }}
              data-aos="fade-up"
            >
              <div class="highlight-title" data-aos="fade-up">
                <h3>Privacy Policy - Simple Ideas GmbH</h3>
                <p>Last Updated: Jan 01, 2024</p>

                <h4>Introduction:</h4>
                <p>
                  Welcome to Simple Ideas GmbH ("we," "our," or "us"). We are
                  committed to protecting your privacy and safeguarding your
                  personal information. This Privacy Policy explains how we
                  collect, use, disclose, and protect your information when you
                  visit our website{" "}
                  <a
                    href="https://www.simpleideas.ch"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.simpleideas.ch
                  </a>{" "}
                  ("Website") and interact with our services. By using our
                  Website, you consent to the practices described in this
                  Privacy Policy.
                </p>

                {/* ... Other sections ... */}

                <h4>Contact Us:</h4>
                <p>
                  If you have any questions, concerns, or requests related to
                  your privacy or this Privacy Policy, please contact us at{" "}
                  <a href="mailto:info@simpleideas.com">info@simpleideas.com</a>
                  .
                </p>

                <h4>Changes to this Privacy Policy:</h4>
                <p>
                  We may update this Privacy Policy from time to time. Any
                  changes will be posted on this page with a revised "Last
                  Updated" date.
                </p>

                <p>
                  Thank you for trusting Simple Ideas GmbH with your
                  information.
                </p>

                <a
                  href="https://www.simpleideas.com/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link to Privacy Policy
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
}
