import { Row, Col } from "antd";
import ButtonBox from "../../Components/ButtonBox/ButtonBox";
import waysofWorkingImg from "../../assets/images/services/ways-of-working.svg";
import IdeasInnovation from "../../assets/images/services/ideas-and-innovation.svg";
import Initiatives from "../../assets/images/services/initiatives.svg";
import ValueImpactCreation from "../../assets/images/services/value-and-impact-creation.svg";
import puzzleImage from "../../assets/images/services/puzzle.svg";
import "./ServicesSection.scss";
import { useHistory } from "react-router-dom";

const servicesList = {
  row1: [
    {
      title: "Ways of Working",
      desc: (
        <p className="mb-0 text-gray">
          Revamp your operating model, Say farewell to the old, complicated work
          styles impact.
        </p>
      ),
      image: [waysofWorkingImg],
      class: "bg-primary",
    },
    {
      title: "Ideas & Innovation",
      desc: (
        <p className="mb-0 text-gray">
          &quot;Good ideas must be more than just thoughts. They need to acted
          on.
        </p>
      ),
      image: [IdeasInnovation],
      class: "bg-yellow",
    },
  ],
  row2: [
    {
      title: "Initiatives",
      desc: (
        <p className="mb-0 text-gray">
          &quot;At the core of thriving organizations lies the power to take
          action.
        </p>
      ),
      image: [Initiatives],
      class: "bg-purple",
    },
    {
      title: "Value & Impact Creation",
      desc: (
        <p className="mb-0 text-gray">Don&apos;t leave your impact to chance</p>
      ),
      image: [ValueImpactCreation],
      class: "bg-green",
    },
  ],
};

export default function ServicesSection() {
  const navigate = useHistory();

  return (
    <section className="services-section spacing section-top1 position-relative">
      <div className="container">
        <h3 className="text-center mb-2 position-relative" data-aos="fade-up">
          Services
        </h3>
        <p className="text-center position-relative mt-2">
          Explore areas in which you can apply simplicity as your core guiding
          principle{" "}
        </p>
        <Row
          gutter={[
            { xs: 0, sm: 12, md: 25, lg: 0 },
            { xs: 0, sm: 12, md: 25, lg: 0 },
          ]}
        >
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            data-aos="fade-up"
          >
            {servicesList.row1.map((service, i) => (
              <div className="card bg-white service-list" key={i}>
                <div className="image">
                  <img
                    src={service.image}
                    alt={service.title}
                    className="w-100"
                  />
                </div>
                <div className="details">
                  <h4>{service.title}</h4>
                  {service.desc}
                </div>
                <div className={`count ${service.class}`}>{i + 1}</div>
              </div>
            ))}
          </Col>
          <Col
            xs={{ span: 0 }}
            sm={{ span: 0 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            data-aos="fade-up"
          >
            <img
              src={puzzleImage}
              className="w-100 puzzle-image"
              alt="Simple Ideas GmbH Services"
            />
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            data-aos="fade-up"
          >
            {servicesList.row2.map((service, i) => (
              <div className="card bg-white service-list reverse" key={i}>
                <div className="image">
                  <img
                    src={service.image}
                    alt={service.title}
                    className="w-100"
                  />
                </div>
                <div className="details">
                  <h4>{service.title}</h4>
                  {service.desc}
                </div>
                <div className={`count ${service.class}`}>{i + 3}</div>
              </div>
            ))}
          </Col>
        </Row>
        <div className="text-center mt-3">
          <ButtonBox
            variant="primary"
            onClick={() => navigate.push("/services")}
            label="Read more"
            rightArrowIcon
          />
        </div>
      </div>
    </section>
  );
}
