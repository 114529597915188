import { Col, Row } from "antd";
import React from "react";
import TitleBox from "../../Components/TitleBox/TitleBox";

export default function TermsandConditions() {
  return (
    <React.Fragment>
      <TitleBox title="Terms and Conditions" align={"center"} />
      <section className="faq spacing light-background">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            className="pb-3 "
            align="center"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 18 }}
              lg={{ span: 18 }}
              data-aos="fade-up"
            >
              <div class="highlight-title" data-aos="fade-up">
                <h4>1. Acceptance of Terms</h4>
                <p>
                  By accessing or using the Simple Ideas GmbH website (the
                  "Website"), you agree to comply with and be bound by these
                  Terms and Conditions. If you do not agree to these Terms and
                  Conditions, please do not use the Website.
                </p>

                <h4>2. Use of Website</h4>
                <p>
                  The content provided on the Website is for informational
                  purposes only. You may not modify, distribute, transmit,
                  display, perform, reproduce, publish, license, create
                  derivative works from, transfer, or sell any information,
                  software, products, or services obtained from the Website.
                </p>

                {/* Add more sections here */}

                <h4>3. User Accounts</h4>
                <p>
                  In order to access certain features of the Website, you may be
                  required to create a user account. You are responsible for
                  maintaining the confidentiality of your account information
                  and for all activities that occur under your account.
                </p>

                <h4>4. Intellectual Property</h4>
                <p>
                  The Website and its content, including text, graphics, logos,
                  and images, are the property of Simple Ideas GmbH and
                  protected by copyright and other intellectual property laws.
                </p>

                <h4>5. Limitation of Liability</h4>
                <p>
                  We are not liable for any direct, indirect, incidental,
                  special, or consequential damages arising out of or in
                  connection with your use of the Website or its content.
                </p>

                <h4>6. Governing Law</h4>
                <p>
                  These Terms and Conditions shall be governed by and construed
                  in accordance with the laws of Switzerland for users accessing
                  the Website from Switzerland, and the laws of India for users
                  accessing the Website from India. Any legal action or
                  proceeding related to the Website shall be brought exclusively
                  in the courts of the respective jurisdiction.
                </p>

                <h4>7. Contact Us</h4>
                <p>
                  If you have any questions or concerns about these Terms and
                  Conditions, please contact us at{" "}
                  <a href="mailto:info@simpleideas.com">info@simpleideas.com</a>
                  .
                </p>

                <h4>8. Changes to Terms</h4>
                <p>
                  We may update these Terms and Conditions from time to time.
                  Any changes will be posted on this page with a revised "Jan
                  08, 2024" date.
                </p>

                <p>Thank you for using Simple Ideas GmbH.</p>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
}
