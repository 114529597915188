import React, { useRef, useEffect, useState } from "react";
import { Carousel, Row, Col } from "antd";
import "./Home.scss";
import weSpecialized from "../../assets/images/we-specialized.svg";
import OperatingPrinciple from "./OperatingPrinciple";
import CTA from "./CTA";
import ServicesSection from "./ServicesSection";
import ButtonBox from "../../Components/ButtonBox/ButtonBox";
import ourApproach from "../../assets/images/our-approach.svg";
import GuidingPrinciple from "./GuidingPrinciple";
import PartnersSection from "./PartnersSection";
import RecentPosts from "./RecentPosts";
// import SuccessStories from "./SuccessStories";
import HomeSliderData from "./HomeSliderData";
import { Link } from "react-router-dom";
import { ArrowRight } from "iconsax-react";
import "./HomeSlider.scss";
import AssetPath from "../../AssetPath/AssetPath";

export default function Home() {
  const sectionRefs = useRef(
    HomeSliderData.reduce((acc, value) => {
      acc[value.sectionId] = React.createRef();
      return acc;
    }, {})
  ).current;

  const scrollToSection = (sectionId) => {
    sectionRefs[sectionId].current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  return (
    <>
      <section className="home-sldier main-page spacing position-relative shape-home-gradient overflow-hidden">
        <div className="container">
          <Carousel autoplay="false" dots="false" fade="true">
            {HomeSliderData.map((data, i) => (
              <div className="slider-list position-relative" key={i}>
                <Row
                  gutter={[
                    { xs: 0, sm: 12, md: 25, lg: 30 },
                    { xs: 0, sm: 12, md: 25, lg: 30 },
                  ]}
                  align="center"
                  justify="middle"
                  key={i}
                >
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                    data-aos="fade-up"
                  >
                    <div className="main-image text-center w-100">
                      <img src={data.image} alt={data.title} />
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                    data-aos="fade-up"
                    className="details"
                  >
                    <h1 className="mb-3">{data.title}</h1>
                    {data.desc ? <p className="text-gray">{data.desc}</p> : ""}
                    <a
                      key={data.link}
                      onClick={() => scrollToSection(data.sectionId)}
                      className="ant-btn ant-btn-primary mt-2"
                    >
                      Explore now
                      <span className="arrow-right">
                        <ArrowRight />
                      </span>
                    </a>
                  </Col>
                </Row>
                <div className="key-message position-absolute bottom-0 start-0">
                  <span>{data.keymessage}</span>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </section>

      {/* <HomeSlider /> */}
      <section
        className="bringing-simplicity spacing position-relative"
        ref={sectionRefs["why-simplify"]}
        id="why-simplify"
      >
        <div className="container">
          <h3 className="text-center font-weight-medium" data-aos="fade-up">
            We specialize in
          </h3>
          <h2 className="text-center font-weight-semibold" data-aos="fade-up">
            bringing <span className="text-primary">simplicity </span>
            to your <span className="text-secondary">Ways of Working</span>
            <span className="d-block">
              so that you can achieve{" "}
              <span className="text-primary">Big Results!</span>
            </span>
          </h2>
          <img
            src={weSpecialized}
            className="w-100 mt-2"
            data-aos="zoom-in"
            alt="Simple Ideas GmbH specialize in - bringing simplicity to your Ways of Working so that you can achieve Big Results!"
          />
        </div>
        <img
          src={AssetPath.shapes.outlineYellowCircle}
          data-aos="fade-left"
          alt="Simple Ideas GmbH"
          className="shape position-absolute"
        />
      </section>

      <div ref={sectionRefs["excessive-complexity"]} id="excessive-complexity">
        <OperatingPrinciple />
      </div>

      <CTA className="mb-5" />

      <div ref={sectionRefs["our-offer"]} id="our-offer">
        <ServicesSection />
      </div>

      <section
        className="our-approach bg-white spacing"
        ref={sectionRefs["our-approach"]}
        id="our-approach"
      >
        <div className="container">
          <Row align="center" justify="middle" gutter={[12, 24]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              data-aos="fade-up"
            >
              <img
                src={ourApproach}
                className="puzzle-image"
                alt="Our Approach"
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              data-aos="fade-up"
            >
              <h3 className="mb-2">Our Approach</h3>
              <p className="text-gray">
                Your current results are the fruit of past thoughts and actions.
                Today's thinking and doing shape the outcomes of tomorrow. It's
                a continuous loop.
              </p>
              <ButtonBox type="primary" rightArrowIcon label="Know More" />
            </Col>
          </Row>
        </div>
      </section>

      <GuidingPrinciple />

      <PartnersSection />

      {/* <div
        className="section-top2 position-relative "
        ref={sectionRefs["success-stories"]}
        id="success-stories"
      >
        <SuccessStories />
      </div> */}

      <RecentPosts showMoreLink />
    </>
  );
}
