import { Button, Col, Row } from "antd";
import { Link } from "react-router-dom";
import AssetPath from "../../AssetPath/AssetPath";
import ButtonBox from "../../Components/ButtonBox/ButtonBox";
import "./NinetyDaysExperiment.scss";

export default function NinetyDaysExperiment() {
  return (
    <>
      <section className="about-us bg-white min-height overflow-hidden">
        <img
          src={AssetPath.shapes.circleYellow}
          alt="Simple Ideas GmbH"
          className="about-circle"
          data-aos="fade-up"
        />
        <div className="container position-relative z-index">
          <Row
            align="center"
            justify="middle"
            gutter={[
              { xs: 0, sm: 12, md: 25, lg: 30 },
              { xs: 0, sm: 12, md: 25, lg: 30 },
            ]}
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
            >
              <h1 className="mb-2 font-2 text-uppercase" data-aos="fade-up">
                90 Days of Experiment{" "}
                <img
                  src={AssetPath.shapes.glitterBlue}
                  width="50px"
                  alt="About Simple Ideas GmbH"
                />
              </h1>
              <h4 className="h2">Ninety Days of Transformation</h4>
              <p className="text-gray">
                Ninety days of laser focus and dedication can kickstart an idea,
                propel an initiative, or steer the course of desired change.
              </p>
              <h3 className="mt-3">Are you willing to experiment?</h3>
              <ButtonBox type="primary" rightArrowIcon label="Lets talk" />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <img
                src={AssetPath.about.AboutImg}
                className="image"
                alt="About Simple Ideas GmbH"
                data-aos="fade-up"
              />
            </Col>
          </Row>
        </div>
      </section>

      <section className="spacing ">
        <div className="container">
          <Row align="middle" justify="center">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 14 }}
              lg={{ span: 14 }}
            >
              <div className="bg-light pre-requsites">
                <Row align="middle" justify="center">
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <h3>Pre-Requisites</h3>
                    <ul>
                      <li>
                        A problem statement or an opportunity that has been
                        lingering for a while or have had several failed
                        attempts of resolution
                      </li>
                      <li>
                        Willingness and commitment to doing things differently{" "}
                      </li>
                    </ul>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <h3 className="mt-3">What to expect?</h3>
                    <p className="text-gray">
                      Experiments are customised to your need and to your
                      desired outcome.{" "}
                    </p>
                    <img
                      src={AssetPath.images.whatToExpect}
                      className="mb-4"
                      alt="what to expect from Simpleideas GmbH"
                    />

                    <p className="text-gray">
                      For more details on “How we do it” please{" "}
                      <Link to="/contact-us">get in touch!</Link>
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="spacing bg12 position-relative">
        <div className="container">
          <Row
            gutter={[
              { xs: 10, sm: 16, md: 24, lg: 32 },
              { xs: 10, sm: 16, md: 24, lg: 20 },
            ]}
            className="pb-3 "
            align="middle"
            justify="center"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <h3>A few example scenarios </h3>
              <ul className="mb-0">
                <li>
                  An initiative has been running for a while without any
                  valuable outcomes.
                </li>
                <li>
                  An idea is stuck at conceptualisation phase and its just
                  difficult to get it off the ground
                </li>
                <li>
                  Cross team collaboration is a drag and a lot of time and
                  energy is being wasted on alignment without any real results
                </li>
                <li>
                  Teams are fatigued from changing all the time or aftermath of
                  a transformation
                </li>
                <li>
                  Simply the old ways of working are just not cutting it any
                  more{" "}
                </li>
              </ul>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <div className="text-center" data-aos="fade-up">
                <img
                  src={AssetPath.about.whysimpleIdeas}
                  className="w-100"
                  alt="Why Simple Ideas GmbH"
                />
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="position-absolute start-0"
          style={{ top: "-3px", margin: "0 auto", right: "0", width: "40%" }}
        >
          <img
            src={AssetPath.divider.cardDesign}
            className="w-100"
            alt="SimpleIdeas GmbH"
          />
        </div>
      </section>
    </>
  );
}
