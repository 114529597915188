import { Facebook, Instagram, Linkedin, Twitter } from "feather-icons-react";
import { Whatsapp, Youtube } from "iconsax-react";

const FooterLinks = [
  {
    title: "Services",
    links: [
      {
        title: "Ways of Working",
        link: "/ways-of-working",
      },
      {
        title: "Ideas & Innovation",
        link: "/ideas-and-innovation",
      },
      {
        title: "Initiatives",
        link: "/initiatives",
      },
      {
        title: "Values & Impact Creation",
        link: "/values-and-impact-creation",
      },
      {
        title: "The Simple Framework",
        link: "/the-simple-framework",
      },
    ],
  },
  {
    title: "Quick Links",
    links: [
      {
        title: "About Us",
        link: "/about-us",
      },
      {
        title: "Our Services",
        link: "/services",
      },
      {
        title: "90 Days Experiment",
        link: "/90-days-experiment",
      },
      {
        title: "Learn a thons",
        link: "#",
      },
    ],
  },
  {
    title: "Privacy & info",
    links: [
      {
        title: "Terms and Conditions",
        link: "/terms-and-conditions",
      },
      {
        title: "Privacy Policy",
        link: "/privacy-policy",
      },
      {
        title: "Get in Touch",
        link: "/contact-us",
      },
      {
        title: "Become a Partner",
        link: "/become-a-partner",
      },
    ],
  },
  //   {
  //     title: "Simple Ideas",
  //     links: [
  //       {
  //         title: "Blogs",
  //         link: "/blogs",
  //       },
  //       {
  //         title: "FAQ's",
  //         link: "/faq",
  //       },
  //     ],
  //   },
  {
    title: "Follow Us",
    links: [
      // {
      //   title: "Facebook",
      //   link: "https://www.facebook.com",
      //   icon: <Facebook />,
      // },
      {
        title: "Linkedin",
        link: "https://www.linkedin.com/company/simple-ideas-gmbh",
        icon: <Linkedin />,
      },
      // {
      //   title: "Twitter",
      //   link: "https://www.twitter.com",
      //   icon: <Twitter />,
      // },
      // {
      //   title: "Instagram",
      //   link: "https://www.instagram.com",
      //   icon: <Instagram />,
      // },
      {
        title: "Youtube",
        link: "https://www.youtube.com",
        icon: <Youtube />,
      },
      // {
      //   title: "Whatsapp",
      //   link: "https://www.whatsapp.com",
      //   icon: <Whatsapp />,
      // },
    ],
  },
];

export default FooterLinks;
