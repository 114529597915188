import React from "react";
import { Formik, Field, Form } from "formik";
import apiClient from "../../Actions/apiClient";
import "../Contact/Contact.scss";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import { ArrowRight } from "iconsax-react";

export default function CareersForm() {
  const history = useHistory();
  const onSend = (values) => {
    apiClient.post("partners/add", values).then(({ data }) => {
      if (data?.result) {
        message.success(
          "Thanks for you interest! Our team will reach out to you shortly"
        );
        history.push("/");
      }
    });
  };
  return (
    <div className="contact-form">
      <Formik
        initialValues={{
          name: "",
          email: "",
          phone: "",
          company: "",
          role: "",
          resume: "",
        }}
        onSubmit={onSend}
      >
        <Form>
          <div className="row">
            <div className="field col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>Name *</label>
              <Field
                name="name"
                required
                label="name"
                placeholder="Enter your  name.."
                type="text"
              />
            </div>
            <div className="field col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label>Email Address *</label>
              <Field
                name="email"
                required
                placeholder="Email Address *"
                type="email"
              />
            </div>
            <div className="field col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label>Phone Number</label>
              <Field
                name="phone"
                placeholder="Enter your phone number"
                type="phone"
              />
            </div>

            <div className="field col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label>Working Company Name</label>
              <Field
                name="company"
                placeholder="Enter your working company name"
              />
            </div>
          </div>

          <div className="row">
            <div className="field col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label>Job Role</label>
              <Field name="role" placeholder="Enter your Job Role" />
            </div>
            <div className="field col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label>Upload Resume</label>
              <Field name="resume" placeholder="Upload Resume.." type="file" />
            </div>
          </div>

          <button className="ant-btn ant-btn-primary w-100" type="submit">
            Submit{" "}
            <span className="ms-1 arrow-right">
              <ArrowRight />
            </span>
          </button>
        </Form>
      </Formik>
    </div>
  );
}
