import roadMapImage from "../../assets/images/services/our-approach.svg";
import "./Roadmap.scss";
import roadMapPath from "../../assets/images/services/roadmap-line.svg";

export default function Roadmap() {
  const roadmapList = [
    {
      title: "Understanding",
      class: "bg-primary",
      content: (
        <p className="mb-0 font-4">
          We believe{" "}
          <span className="font-weight-medium">
            “A problem well understood is half resolved”
          </span>
          . We begin by understanding the problem/opportunity. We also take a
          good look at your context - how is the opportunity related to other
          areas of your business and external factors. Goes without saying we
          take a note of your expectations
        </p>
      ),
    },
    {
      title: "Solutioning",
      class: "bg-purple",
      content: (
        <p className="mb-0 font-4">
          Together we explore{" "}
          <span className="font-weight-medium">simple doing pathways</span> and
          solutions.Doing pathways are subjective, they depend on various
          factors such as your capabilities, strengths, gap areas to name some.
          Adopting a doing methodology just because it is in hype does not
          necessarily mean it is your way forward. Likewise there is a solution
          to every problem - but your solution needs to meet your needs! “Same
          shoe size does not fit all”
        </p>
      ),
    },
    {
      title: "Doing",
      class: "bg-black",
      content: (
        <p className="mb-0 font-4">
          We help you to set things in motion by determining your specific
          staring point and identifying simplifiers that can accelerate your
          path towards goals
        </p>
      ),
    },
  ];

  return (
    <section className="roadmap">
      <div className="container">
        <div className="bg7 rounded position-relative">
          <div className="title-sec" data-aos="fade-up">
            <h4 className="h3 mb-2 font-weight-semibold">Our Approach</h4>
            <h5>We are jargon free!</h5>
            <p>
              We help you find and implement{" "}
              <span className="font-weight-medium">simple doing pathways</span>{" "}
              for your complex problems or opportunities!
            </p>
          </div>
          <img
            className="roadmap-line position-absolute"
            src={roadMapPath}
            alt="SimpleIdeas GmbH Roadmap"
          />
          <div className="roadmap">
            {roadmapList.map((list, i) => (
              <div
                className={`roadmap-list list${i + 1}`}
                key={i}
                data-aos="fade-up"
              >
                <div className={`count ${list.class}`}>0{i + 1}</div>
                <div className="details">
                  <h3 className="mb-0">{list.title}</h3>
                  <div>{list.content}</div>
                </div>
              </div>
            ))}
          </div>
          <img
            src={roadMapImage}
            alt="Our Approach"
            className="office position-absolute"
          />
        </div>
      </div>
    </section>
  );
}
