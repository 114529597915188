import { Col, Row } from "antd";
import { ArrowRight } from "iconsax-react";
import { Link } from "react-router-dom";
import "./Services.scss";
import circleTransparent from "../../assets/images/shapes/circle-transparent.svg";
import AssetPath from "../../AssetPath/AssetPath";

export default function ServiceTitleSection({
  title,
  ShortDesc,
  DetailedDesc,
  pageLink,
  image,
  themeColor,
  extraText,
}) {
  return (
    <>
      <section
        className={`service-title-section spacing position-relative ${
          themeColor ? `${themeColor}` : ""
        }`}
      >
        <div className="container">
          <img
            src={circleTransparent}
            className="circle-transparent position-absolute"
            alt="Simple ideas GmbH"
          />
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 40 },
              { xs: 0, sm: 16, md: 24, lg: 40 },
            ]}
            className=""
            justify="center"
            align="middle"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              data-aos="fade-up"
            >
              <div className="position-relative z-index" data-aos="fade-up">
                <h1
                  className="font-2 font-weight-medium text-uppercase mb-3"
                  data-aos="fade-up"
                >
                  {title}
                </h1>
                <h2 className="h1 text-black mb-3" data-aos="fade-up">
                  {ShortDesc}
                </h2>
                <p className="mb-3 text-gray" data-aos="fade-up">
                  {DetailedDesc}
                </p>
                <Link
                  to={pageLink}
                  className="ant-btn ant-btn-primary"
                  data-aos="fade-up"
                >
                  Let’s talk{" "}
                  <span className="arrow-right">
                    <ArrowRight className="ms-2" />
                  </span>
                </Link>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              data-aos="fade-up"
            >
              <div className="position-relative z-index">
                <div className="text-end">
                  <img src={image} alt={title} data-aos="zoom-up" />
                </div>
                {extraText ? (
                  <p className="mt-2 text-left" data-aos="fade-up">
                    {extraText}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </div>
        <div className="position-absolute start-0 bottom-0">
          <img
            src={AssetPath.divider.circleDivider}
            className="w-100"
            alt="SimpleIdeas GmbH"
          />
        </div>
      </section>
    </>
  );
}
