import ServiceTitleSection from "./ServiceTitleSection";
import ServiceTitleSectionData from "./ServiceTitleSectionData";
import CalltoAction from "./CalltoAction/CalltoAction";
import SectionBox from "../../Components/SectionBox/SectionBox";
import ImpactStrategy from "../../assets/images/services/value-and-impact-creation/impact-strategy.svg";
import impacttoDecisionEnablement from "../../assets/images/services/value-and-impact-creation/impact-to-decision-enablement.svg";
import insightsDrivenValueBasedPortfolio from "../../assets/images/services/value-and-impact-creation/insights-driven-value-based-portfolio.svg";
import learningImprovemenHubs from "../../assets/images/services/value-and-impact-creation/learning-improvement-hubs.svg";
import realTimeValueMeasurement from "../../assets/images/services/value-and-impact-creation/real-time-value-measurement.svg";
import RelatedServices from "./RelatedServices";

const sectionsData = [
  {
    align: "normal",
    title: "Impact Strategy",
    desc: [
      "Impact is the final stop on a lengthy and intricate journey of thinking and doing, whether on a strategic or tactical level. This journey can be convoluted, making it challenging for organizations, teams, and individuals to gauge impact.",
      "Discover how you can simplify your strategies, focusing on anticipated outcomes and past results.",
    ],
    image: ImpactStrategy,
  },
  {
    title: "Insights driven Value based portfolio",
    desc: [
      "Stay in the loop on how your organization Creates value. We blend your goals and data for crystal-clear insight into your initiative and investment portfolio. Plus, we offer market-driven recommendations to help you optimize your strategy.",
      "We'll provide a high-level view of your portfolio and guide you in optimizing it for maximum impact.",
    ],
    image: insightsDrivenValueBasedPortfolio,
  },
  {
    align: "normal",
    title: "Real time value measurement",
    desc: [
      "Don't rely on luck for success. Measure and enhance your progress in real time. Throughout an idea's lifecycle, it evolves through different value stages. Our unique and straightforward method breaks down value into expected, real, and perceived categories. These three types of value provide insights into the impact of your efforts.",
      "Track the real-time performance of your expected benefits. We've simplified feedback collection and insights for your convenience.",
    ],
    image: realTimeValueMeasurement,
  },
  {
    title: "Impact to decision Enablement",
    desc: [
      "Decisions drive organizational success. Our innovative approach blends intuition with hard data for clear, confident choices. Today's outcomes stem from past actions, and tomorrow's hinge on today's moves.",
      "Learn to excel in this essential skill on a larger scale with our straightforward principles.",
    ],
    image: impacttoDecisionEnablement,
  },
  {
    align: "normal",
    title: "Learning & Improvement Hubs",
    desc: [
      "Learning from anything is possible. We back organizations aiming to make learning their foundation, leveraging it to enhance future actions. We assist in building mechanisms for initiative learning, shameless sharing, and deeply ingraining it in your DNA.",
      "We specialize in facilitating learning culture and establishing learning hubs - much essential for any organization wanting to priorities growth.",
    ],
    image: learningImprovemenHubs,
  },
];

const sectionComponents = sectionsData.map((data, index) => (
  <SectionBox
    key={index}
    align={data.align}
    title={data.title}
    desc={data.desc}
    image={data.image}
  />
));

export default function ValuesImpactCreation() {
  const ValuesImpactCreationData =
    ServiceTitleSectionData.pages[0].valuesandImpactChaneg;
  return (
    <>
      <ServiceTitleSection
        title={ValuesImpactCreationData.title}
        ShortDesc={ValuesImpactCreationData.shortDesc}
        DetailedDesc={ValuesImpactCreationData.DetailedDesc}
        pageLink={ValuesImpactCreationData.link}
        image={ValuesImpactCreationData.image}
        themeColor={ValuesImpactCreationData.themeColor}
        extraText={ValuesImpactCreationData.extraText}
      />
      <div className="shape-blue-gradient shape-green-gradient">
        {sectionComponents}
      </div>

      <RelatedServices />

      <CalltoAction
        heading="Not bluff about outcomes?"
        subheading="Start your 90 days experiment"
        color="black"
        themeColor="bg2"
        spacing="spacing"
      />
    </>
  );
}
