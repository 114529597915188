import React from "react";
import { Formik, Field, Form } from "formik";
import apiClient from "../../Actions/apiClient";
import { useHistory } from "react-router-dom";
import { Col, Row, message } from "antd";
import circleBlack from "../../assets/images/shapes/circle-black.svg";
import { ArrowRight } from "iconsax-react";
import AssetPath from "../../AssetPath/AssetPath";

export default function NewsletterSubscription({ className }) {
  const history = useHistory();
  const onSend = (values) => {
    apiClient.post("subscribers/add", values).then(({ data }) => {
      if (data?.result) {
        message.success(
          "Thanks for your interest in Email Newsletter Subscription!"
        );
        history.push("/");
      }
    });
  };

  return (
    <section
      className={`newsletter-email-capture bg2 spacing position-relative overflow-hidden ${
        className || " "
      }`}
    >
      <img
        src={circleBlack}
        alt="Simpel Ideas GmbH"
        className="position-absolute"
      />
      <div className="container">
        <Row gutter={[12, 24]} align="center" justify="middle">
          <Col xs={24} sm={24} md={18} lg={18}>
            <div className="subscription bg-white border-rdius">
              <div className="image">
                <img
                  src={AssetPath.images.GetUpdate}
                  alt="Get Updates from simple ideas"
                  className="w-100 postman"
                />
              </div>
              <div className="details">
                <h3>Subscribe to our newsletter</h3>
                <p className="text-gray">
                  Be the first to get the latest news and exclusive offers
                </p>
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                  }}
                  onSubmit={onSend}
                >
                  <Form className="d-flex align-items-center flex-column justify-content-between">
                    <Field
                      name="name"
                      required
                      placeholder="Full Name *"
                      type="name"
                    />
                    <Field
                      name="email"
                      required
                      placeholder="Email Address *"
                      type="email"
                    />
                    <button
                      className="ant-btn ant-btn-submit w-100"
                      type="submit"
                    >
                      Subscribe{" "}
                      <span className="ms-1 arrow-right">
                        <ArrowRight />
                      </span>
                    </button>
                  </Form>
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}
