import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Header.scss";
import simpleideasLogo from "../assets/images/simpleideas.svg";
import simpleideasWhite from "../assets/images/simpleideas-white.svg";
import idealServices from "../assets/images/services/ideal-services.svg";
import {
  HambergerMenu,
  CloseCircle,
  Whatsapp,
  ArrowRight,
} from "iconsax-react";
import ButtonBox from "../Components/ButtonBox/ButtonBox";
import { ChevronDown } from "feather-icons-react";

const menuItems = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Services",
    link: "/services",
    submenu: [
      {
        title: "Ways of Working",
        link: "/ways-of-working",
      },
      {
        title: "Ideas & Innovation",
        link: "/ideas-and-innovation",
      },
      {
        title: "Initiatives",
        link: "/initiatives",
      },
      {
        title: "Values & Impact Creation",
        link: "/values-and-impact-creation",
      },
    ],
  },
  {
    title: "About us",
    link: "/about-us",
  },
  {
    title: "Blogs",
    link: "/blogs",
  },
  {
    title: "Get in Touch",
    link: "/contact-us",
  },
];

export default function Header() {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const navigate = useHistory();

  const MenuItem = ({ title, link, submenu }) => {
    return (
      <li>
        <Link
          to={link}
          className={`${submenu ? "parent-submenu" : "child-submenu"}`}
          onClick={() => setIsMenuActive(false)}
        >
          {title}
          {submenu ? <ChevronDown size={14} /> : ""}
        </Link>
        {submenu && submenu.length > 0 && (
          <div className="sub-menu">
            <div className="menu-details">
              <div className="links">
                <h4>Our Services</h4>
                <ul>
                  {submenu.map((subitem, index) => (
                    <MenuItem key={index} {...subitem} />
                  ))}
                </ul>
              </div>
              <div className="discover-ideal-services bg1">
                <h4>Discover Your Ideal Service</h4>
                <p className="font-4 font-gray">
                  Answer a few questions and find the ideal solution that
                  preferences effortlessly
                </p>
                <ButtonBox
                  rightArrowIcon
                  label="Select Services"
                  onClick={() => navigate.push("/service-selection")}
                />
                <img
                  src={idealServices}
                  alt="Simple Ideas GmbH Discover Your Ideal Service"
                />
              </div>
            </div>
          </div>
        )}
      </li>
    );
  };

  const NavigationMenu = ({ menuItems }) => {
    return (
      <ul>
        {menuItems.map((menuItem, index) => (
          <MenuItem key={index} {...menuItem} />
        ))}
      </ul>
    );
  };

  return (
    <React.Fragment>
      <div className="header">
        <nav className="container">
          <div className="flex-menu">
            <div className="brand">
              <Link to="/">
                <img
                  src={simpleideasLogo}
                  className="dark-logo"
                  alt="Simple Ideas GmbH"
                />
                <img
                  src={simpleideasWhite}
                  className="white-logo"
                  alt="Simple Ideas GmbH"
                />
              </Link>
            </div>
            <div
              className={`right-menu flex-design ${
                isMenuActive ? "active" : ""
              }`}
            >
              <div className="nav-menu">
                <NavigationMenu menuItems={menuItems} />
              </div>
              <div className="mobile-cta">
                <div className="discover-ideal-services bg1">
                  <div className="content">
                    <h4>Discover Your Ideal Service</h4>
                    <p className="font-4 font-gray">
                      Answer a few questions and find the ideal solution that
                      preferences effortlessly
                    </p>
                    <ButtonBox
                      rightArrowIcon
                      label="Select Services"
                      onClick={() => navigate.push("/service-selection")}
                    />
                  </div>
                  <div className="image">
                    <img
                      src={idealServices}
                      alt="Simple Ideas GmbH Discover Your Ideal Service"
                    />
                  </div>
                </div>
              </div>
              <div className="cta">
                <a
                  href="tel:+41 78 649 15 41"
                  className="me-4 text-black font-weight-bold phone"
                >
                  <Whatsapp className="me-2" />
                  +41 78 649 15 41
                </a>
                <a
                  className="ant-btn ant-btn-primary"
                  href="https://calendly.com/prath-newnham/free-consultation"
                  target="_blank"
                >
                  Free Consultation{" "}
                  <span className="arrow-right">
                    <ArrowRight />
                  </span>
                </a>
              </div>
            </div>
            <div
              className={`hamburger-menu ${isMenuActive ? "fixed" : ""}`}
              onClick={() => setIsMenuActive(!isMenuActive)}
            >
              {isMenuActive ? <CloseCircle /> : <HambergerMenu />}
            </div>
          </div>
        </nav>
      </div>
    </React.Fragment>
  );
}
