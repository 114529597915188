import React, { useEffect } from "react";
import { Layout } from "antd";
import { useLocation } from "react-router-dom"; // Assuming you're using React Router
import HeaderNav from "./Header";
import FooterNav from "./Footer";

const { Content } = Layout;

const Layouts = (props) => {
  const location = useLocation();
  const isHome = location.pathname === "/"; // Assuming the home page URL is "/"

  useEffect(() => {
    window.scrollTo(0, 0);

    // Add or remove "home" class from body element
    if (isHome) {
      document.body.classList.add("home");
    } else {
      document.body.classList.remove("home");
    }

    // Clean up the class on unmount
    return () => {
      document.body.classList.remove("home");
    };
  }, [location, isHome]);

  return (
    <Layout style={{ minHeight: "100vh" }} className="site-layout">
      <HeaderNav {...props} />
      <Content>{props.children}</Content>
      <FooterNav />
    </Layout>
  );
};

export default Layouts;
