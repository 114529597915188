import React from "react";
import { Row, Col } from "antd";
import ResponsiveImage from "../../assets/images/icons/responsive.png";
import ProactiveImage from "../../assets/images/icons/proactive.png";
import RelevantImage from "../../assets/images/icons/relevant.png";
import AssetPath from "../../AssetPath/AssetPath";

const OperatingPrinciples = [
  {
    title: "Responsive",
    desc: "To become a company that's rock-solid, ready to move fast, adapt, and thrive in today's ever-changing world, and tomorrow's too. Keep it Simple!",
    icon: [ResponsiveImage],
    bg: "bg1",
  },
  {
    title: "Proactive",
    desc: "To be a place where doing matters more than talking. Actions speak louder than words.",
    icon: [ProactiveImage],
    bg: "bg2",
  },
  {
    title: "Relevant",
    desc: "To be a place where ideas and actions create actual value and results that matter.",
    icon: [RelevantImage],
    bg: "bg3",
  },
];

export default function OperatingPrinciple() {
  return (
    <section className="spacing bg-white why-simplified-way">
      <div className="container">
        <Row
          gutter={[
            { xs: 0, sm: 16, md: 24, lg: 32 },
            { xs: 0, sm: 16, md: 24, lg: 32 },
          ]}
          className="pb-3 "
        >
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            data-aos="fade-up"
          >
            <div className="operating-principle">
              <h4 className="h3 mb-3">
                Embrace <span className="text-secondary">Simplicity</span> as
                your core operating principle
              </h4>
              <Row
                gutter={[
                  { xs: 10, sm: 12, md: 12, lg: 12 },
                  { xs: 10, sm: 12, md: 12, lg: 12 },
                ]}
                className="pb-3 postion-relative"
              >
                {OperatingPrinciples.map((data, i) => (
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: i === 0 ? 24 : 12 }}
                    lg={{ span: i === 0 ? 24 : 12 }}
                    data-aos="fade-up"
                    key={i}
                  >
                    <div className={`card position-relative ${data.bg}`}>
                      <div className="icon bg-white rounded-circle">
                        <img
                          src={data.icon}
                          alt={data.title}
                          className="w-100"
                        />
                      </div>
                      <div className="content">
                        <h4>{data.title}</h4>
                        <p className="text-gray mb-0">{data.desc}</p>
                      </div>
                    </div>
                  </Col>
                ))}
                <img
                  src={AssetPath.shapes.waveLineYellow}
                  alt="Simple Ideas GmbH"
                  className="shape position-absolute"
                />
              </Row>
            </div>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            data-aos="fade-up"
          >
            <h4 className="text-primary h3 font-weight-semibold mb-2">
              Why is there a need to Simplify Ways of working?
            </h4>
            <p className="text-gray">
              Complexity is everywhere, especially in our fast-paced world where
              things are always changing. We're super connected, drowning in
              information, and navigating a web of dependencies to bring ideas
              to life or run a successful organization. It often feels like
              we're trying to find our way through a complicated maze.
            </p>
            <p className="text-gray">
              While problems are undoubtedly complex, using a complex mindset to
              solve them is a dead-end. Complexity is the greatest killer of
              value!. Organizational complexity is a real troublemaker, that
              affects individuals, teams, and the entire organization by
              blocking the pathways to value. Bureaucracy, clunky processes,
              mindset, and culture threaten an organization's ability to get
              things done, grow, and stay relevant.
            </p>
            <p className="text-gray">
              As da Vinci wisely said,{" "}
              <span className="font-weight-semibold">
                "Simplicity is the ultimate sophistication"
              </span>{" "}
              – easier said than done! Simplicity isn't just about streamlining
              processes; it's a way of operating. By reducing unnecessary and
              unwanted complexity, you can invest your time, effort, and
              resources in things that truly matter, creating real value and
              impact.
            </p>
          </Col>
        </Row>
      </div>
    </section>
  );
}
