import { Facebook, Instagram, Linkedin, Twitter } from "feather-icons-react";
import "./SocialShare.scss";

export default function SocialShare() {
  const facebookShareURL = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`;
  const twitterShareURL = `https://twitter.com/share?url=${window.location.href}`;
  const linkedInShareURL = `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`;
  const redditShareURL = `https://www.reddit.com/submit?url=${window.location.href}`;

  /* <a class="sm:px-0" href="https://www.facebook.com/sharer/sharer.php?u=https://cleartax.com/sa/ksa-einvoicing" id="facebookShare" data-action="" target="_blank" rel="noopener noreferrer" title="share on facebook"> */

  const SocialShareLink = [
    {
      platform: "Facebook",
      className: "sm:px-0",
      href: facebookShareURL,
      id: "facebookShare",
      dataAction: "",
      target: "_blank",
      rel: "noopener noreferrer",
      title: "share on facebook",
      iconClass: <Facebook />,
    },
    {
      platform: "LinkedIn",
      className: "sm:px-0",
      href: linkedInShareURL,
      id: "linkedInShare",
      dataAction: "",
      target: "_blank",
      rel: "noopener noreferrer",
      title: "share on linkedIn",
      iconClass: <Linkedin />,
    },
    {
      platform: "Twitter",
      className: "sm:px-0",
      href: twitterShareURL,
      id: "twitterShare",
      dataAction: "",
      target: "_blank",
      rel: "noopener noreferrer",
      title: "share on twitter",
      iconClass: <Twitter />,
    },
  ];

  return (
    <div className="social-share">
      <ul>
        {SocialShareLink.map((data, i) => (
          <li key={i} className="list-unstyled">
            <a
              className={data.className}
              href={data.href}
              id={data.id}
              data-action={data.dataAction}
              target={data.target}
              rel={data.rel}
              title={data.title}
            >
              {data.iconClass}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
