import { useRef } from "react";
import { ArrowRight } from "iconsax-react";
import { Link, useHistory } from "react-router-dom";
import PostData from "../Blogs/PostData";
import { Carousel } from "antd";
import { ChevronLeft, ChevronRight } from "feather-icons-react";
import "./RecentPosts.scss";
import ButtonBox from "../../Components/ButtonBox/ButtonBox";

export default function RecentPosts({ showMoreLink }) {
  const recentPosts = PostData.slice(0, 10);
  const history = useHistory();
  const carouselRef = useRef();

  const handleNext = () => {
    carouselRef.current.next();
  };

  const handlePrevious = () => {
    carouselRef.current.prev();
  };

  const carouselConfig = {
    draggable: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 1500,
    infinite: true,
    slidesToShow: 4,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="recent-post spacing bg10">
      <div className="container">
        <div
          className=" heading d-flex align-items-center justify-content-between mb-2"
          data-aos="fade-up"
        >
          <h3>Recent Posts from Blogs</h3>
          <div className="d-flex align-items-center justify-content-center text-center mt-3">
            <div className="slide-navigations">
              <div
                className="previous me-2 cursor-pointer"
                onClick={handlePrevious}
              >
                <ChevronLeft size={18} />
              </div>
              <div className="next cursor-pointer" onClick={handleNext}>
                <ChevronRight size={18} />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Carousel {...carouselConfig} ref={carouselRef}>
            {recentPosts.map((post, i) => (
              <Link to={post.link}>
                <div key={i}>
                  <div className="post-details bg-white">
                    <img
                      src={post.thumbnail}
                      alt={post.title}
                      className="w-100"
                    />
                    <div className="details">
                      <h4 className="text-ellipsis2 text-black">
                        {post.title}
                      </h4>
                      <p className="text-gray font-4 text-ellipsis2">
                        {post.shortDesc}
                      </p>
                      <Link
                        to={post.link}
                        className="font-4 text-primary more-details"
                      >
                        More Details <ArrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </Carousel>
        </div>
        {showMoreLink ? (
          <div className="text-center mt-2">
            <ButtonBox
              arrow
              label="More Blogs"
              onClick={() => history.push("/blogs")}
              rightArrowIcon
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </section>
  );
}
