import ServiceTitleSection from "./ServiceTitleSection";
import ServiceTitleSectionData from "./ServiceTitleSectionData";
import CalltoAction from "./CalltoAction/CalltoAction";
import SectionBox from "../../Components/SectionBox/SectionBox";
import PeoplefirstWay from "../../assets/images/services/initiatives/people-first-way.svg";
import StrategyReadinessApproach from "../../assets/images/services/initiatives/strategy-readiness-approach.svg";
import DeliveryExcellence from "../../assets/images/services/initiatives/delivery-excellence.svg";
import Execution from "../../assets/images/services/initiatives/execution.svg";
import RelatedServices from "./RelatedServices";

const sectionsData = [
  {
    align: "normal",
    title: "Change: People first Way",
    desc: [
      "Doing something (An Initiative) often equals to Changing transforming something. And we all know it or have experienced it ourselves - Change isn't always welcome. Afterall who likes the anxiety of something new, doing things differently etc... !",
      "Resistance peaks when Change is forced -learn how to place people at the heart of your initiatives for better chance with success. Let's explore how to make that Change people friendly and adoptable by design!",
    ],
    image: PeoplefirstWay,
  },
  {
    title: "Strategy, Readiness & Approach",
    desc: [
      "A little bit of preparation goes a long way! Just because you are agile does not mean that preparation is not required - lack there of often results in problems in your execution phase. Look a little before you leap.",
      "Discover how to lay the groundwork for successful initiatives by focusing on initial preparations. We specialize in helping you with the basics or essentials that you may need to build solid foundations enabling you to execute with real (not fake) agility.",
    ],
    image: StrategyReadinessApproach,
  },
  {
    align: "normal",
    title: "Delivery Excellence",
    desc: [
      "Like a high-performance car relies on a strong engine, successful initiatives need a dependable hub. It offers support, guidance, and simplifies processes, allowing execution-focused individuals to excel. We specialize in setting up efficient, uncomplicated hubs.",
      "We specialize in setting up Change/Transformation/Program Management Hubs. Explore our clock dial approach - an unique simple way of establishing a heart center for your initiatives. We create connectedness and coherence.",
    ],
    image: DeliveryExcellence,
  },
  {
    title: "Execution",
    desc: [
      "We help your delivery teams shine by simplifying their path to excellence.",
      "Our teams collaborate seamlessly with your execution teams to enhance efficiency. They approach your context with empathy and adaptability.",
    ],
    image: Execution,
  },
];

const sectionComponents = sectionsData.map((data, index) => (
  <SectionBox
    key={index}
    align={data.align}
    title={data.title}
    desc={data.desc}
    image={data.image}
  />
));

export default function Initiatives() {
  const initiativesData = ServiceTitleSectionData.pages[0].initiatives;
  return (
    <>
      <ServiceTitleSection
        title={initiativesData.title}
        ShortDesc={initiativesData.shortDesc}
        DetailedDesc={initiativesData.DetailedDesc}
        pageLink={initiativesData.link}
        image={initiativesData.image}
        themeColor={initiativesData.themeColor}
      />
      <div className="shape-blue-gradient shape-purple-gradient">
        {sectionComponents}
      </div>
      <RelatedServices />
      <CalltoAction
        heading="Roll up your sleeves?"
        subheading="Start your 90 days experiment"
        color="black"
        themeColor="bg2"
        spacing="spacing"
      />
    </>
  );
}
