import React from "react";
import ButtonBox from "../../../Components/ButtonBox/ButtonBox";
import circleWhite from "../../../assets/images/shapes/circle-white.svg";
import abstractFlower from "../../../assets/images/shapes/abstract-flower.png";
import { useHistory } from "react-router-dom";

export default function CalltoAction({
  heading,
  subheading,
  linkText,
  link,
  color,
  themeColor,
  spacing,
}) {
  const navigate = useHistory();

  return (
    <>
      <section
        className={`callto-action  text-center position-relative overflow-hidden ${themeColor} ${
          spacing || "spacing"
        }`}
      >
        <img
          src={circleWhite}
          alt="Simpel Ideas GmbH"
          className="position-absolute start-0 rotate"
        />
        <img
          src={abstractFlower}
          alt="Simpel Ideas GmbH"
          className="position-absolute flower-image"
        />
        <div className="container">
          <h4
            className={`h2 text-capitalize ${
              color === "white" ? "text-white" : "text-black"
            } `}
          >
            {heading}
          </h4>
          <p
            className={`h4 mb-3 font-weight-medium ${
              color === "white" ? "text-white" : "text-black"
            }`}
          >
            {subheading}
          </p>
          <ButtonBox
            rightArrowIcon
            label={linkText || "Lets Talk"}
            to={link}
            onClick={() => navigate.push("/contact-us")}
          />
        </div>
      </section>
    </>
  );
}
