import { Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { ArrowRight } from "iconsax-react";
import "./Careers.scss";
import AssetPath from "../../AssetPath/AssetPath";
import CareersForm from "./CareersForm";

export default function Careers() {
  return (
    <React.Fragment>
      <section className=" min-viewport-height main-page d-flex  align-items-center justify-content-center contact-title-section bg-white spacing bg-blue-gradient position-relative">
        <div className="overflow-hidden w-100">
          <div className="container">
            <div class="highlight-title mt-4 text-center" data-aos="fade-up">
              <span className="h3 d-block text-black">
                Careers
                <img
                  src={AssetPath.shapes.glitterBlue}
                  width="50px"
                  alt="About Simple Ideas GmbH"
                />
              </span>
              <h3 className="h1">Become a Part of Our Team</h3>
              <p className="lead text-black mt-3">
                Here's your chance to join a unique
                <span className="d-block">
                  {" "}
                  company on an incredible, life-changing mission.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="position-absolute start-0 bottom-0">
          <img
            src={AssetPath.divider.circleDivider}
            className="w-100"
            alt="SimpleIdeas GmbH"
          />
        </div>
      </section>
      <section className="contact-support spacing light-background position-relative">
        <div className="container">
          <img
            src={AssetPath.shapes.zigzagShape}
            className="position-absolute zigzag-shape"
            alt="SimpleIdeas GmbH"
          />
          <Row
            gutter={[
              { xs: 10, sm: 16, md: 24, lg: 32 },
              { xs: 10, sm: 16, md: 24, lg: 20 },
            ]}
            className="pb-3 "
            align="middle"
            justify="center"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <div class="highlight-title" data-aos="fade-up">
                <h3 className="text-center mb-3">
                  We're here to help you through the process.
                </h3>
              </div>
              <CareersForm />
              <p className="mt-4 text-center">
                You can also submit your resume to{" "}
                <a
                  href="mailto: hr@simpleideas.com"
                  className="text-black font-weight-bold"
                >
                  hr@simpleideas.com
                </a>
              </p>
            </Col>
          </Row>
          <img
            src={AssetPath.shapes.BlueCircle}
            className="position-absolute circle-blue-shape"
            alt="SimpleIdeas GmbH"
          />
        </div>
      </section>
    </React.Fragment>
  );
}
