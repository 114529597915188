import keepitSimple from "../../assets/images/blogs/keep-it-simple-silly.svg";
import unleashthePower from "../../assets/images/blogs/unleash-the-power-of-simplicity.svg";
import kickingComplexProblems from "../../assets/images/blogs/kicking-complex-problem-to-the-curb-with-simple-solution.svg";
import AssetPath from "../../AssetPath/AssetPath";

const PostData = [
  {
    title:
      "Keep It Simple, Silly: How a Post-It Note Saved the Day at the Office",
    shortDesc:
      "In the hustle and bustle of the modern workplace, it's easy to get lost in the labyrinth of complex problems.",
    thumbnail: [keepitSimple],
    link: "/keep-it-simple-silly-how-a-post-it-note-saved-the-day-at-the-office",
  },
  {
    title: "Unleash the Power of Simplicity: A Workplace Odyssey",
    shortDesc:
      "Workplace conundrums can sometimes resemble mind-boggling puzzles. But, fret not! Let's dive into a fun, real-world example..",
    thumbnail: [unleashthePower],
    link: "/unleash-the-power-of-simplicity-a-workplace-odyssey",
  },
  {
    title: "Kicking Complex Problems to the Curb with Simple Solutions",
    shortDesc:
      "Work can sometimes feel like a never-ending labyrinth of complex problems and mind-boggling challenges. But guess what?..",
    thumbnail: [kickingComplexProblems],
    link: "/kicking-complex-problems-to-the-curb-with-simple-solutions-the-office-coffee-debacle",
  },
  {
    title: "The Curse of Complexity: How It's Killing Our Work Mojo",
    shortDesc:
      "Hey there, work warriors! We've all been there, right? Staring at our tasks, tangled in a web of complexity that's sucking the life out of our productivity.",
    thumbnail: AssetPath.blogs.workMojo,
    link: "/the-curse-of-complexity-how-it's-killing-our-work-mojo",
  },
  {
    title: "Navigating Workplace Complexity: A Survival Guide",
    shortDesc:
      "Hey, busy bees! We've all been there - workdays that feel like a Rubik's Cube on steroids. But what is this beast called workplace complexity, where does it come from, and how can we spot it?",
    thumbnail: AssetPath.blogs.workplaceComplexity,
    link: "/navigating-workplace-complexity-a-survival-guide",
  },
  {
    title: "Agile vs Real Agility: Unmasking the Superhero of Simplicity",
    shortDesc:
      "Hey there, folks! Ever heard of fake agile or wondered why people confuse it with real agility? It's like mistaking a paper plane for a jet..",
    thumbnail: AssetPath.blogs.superHero,
    link: "/agile-vs-real-agility-unmasking-the-superhero-of-simplicity",
  },
];

export default PostData;
