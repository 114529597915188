import ServiceTitleSection from "./ServiceTitleSection";
import ServiceTitleSectionData from "./ServiceTitleSectionData";
import CalltoAction from "./CalltoAction/CalltoAction";
import SectionBox from "../../Components/SectionBox/SectionBox";
import AgilityBooster from "../../assets/images/services/ways-of-working/agility-booster.svg";
import dynamicStrategy from "../../assets/images/services/ways-of-working/dynamic-strategy.svg";
import CollectiveCollaboration from "../../assets/images/services/ways-of-working/collective-collaboration.svg";
import LeadershipDevelopment from "../../assets/images/services/ways-of-working/leadership-development.svg";
import Mindset from "../../assets/images/services/ways-of-working/mindset.svg";
import Culture from "../../assets/images/services/ways-of-working/culture.svg";
import RelatedServices from "./RelatedServices";

const sectionsData = [
  {
    align: "normal",
    title: "Agility Booster",
    desc: [
      "Agility means your organization can swiftly adapt to change and take meaningful action. It's the product of people, processes, and technology working in harmony. But remember, agility isn't an overnight feat—it demands ongoing practice and discipline.",
      "Join us to discover innovative approaches for cultivating the agility you need to thrive in today's dynamic world. Explore fresh ways to build this essential skill with us!",
    ],
    image: AgilityBooster,
  },
  {
    title: "Future aligned Dynamic strategy development",
    desc: [
      "Think of strategy like a two-sided coin. It only works when you consider both sides: what's happening around you (outward) and what's going on within (inward).",
      "Discover how we craft strategies that adapt to our constantly changing world. Our approach blends insights, intuition, and human intelligence to shape future-ready strategies.",
    ],
    image: dynamicStrategy,
  },
  {
    align: "normal",
    title:
      "Collective Collaboration Team Cohesion, Communication & cross functional collaboration",
    desc: [
      "We bring diverse talents together for common goals, creating a balanced and non-bureaucratic synergy. Stronger as a team!",
      "Discover how to build teams swiftly, enhance cohesion, and drive collective action. Our 'Lego Block Principle' fosters collaboration, making teamwork an enjoyable experience.",
    ],
    image: CollectiveCollaboration,
  },
  {
    title: "Leadership Development",
    desc: [
      "Leadership isn't a one-way street. It's about leading and being led.",
      "Discover fresh ways to understand 'how people want to be led,' not just 'how to lead.' True leaders adapt to this difference. ",
    ],
    image: LeadershipDevelopment,
  },
  {
    align: "normal",
    title: "Mindset",
    desc: [
      "Human potential knows no bounds. Our history proves it with remarkable advancements in various fields. Imagination is the spark for a creative, forward-moving organization. It all begins with mindset",
      "Discover harness the power of mindset through our TDR (Think Do Results) approach",
    ],
    image: Mindset,
  },
  {
    title: "Culture",
    desc: [
      "Culture is fascinating. It's tied to time, learned beliefs, and community practices. Organizational culture - Can you create it with a change initiative or renaming a department? No way.",
      "Let's uncover real culture with people-first principles! Join us to discover how you can create a shift for better outcomes",
    ],
    image: Culture,
  },
];

const sectionComponents = sectionsData.map((data, index) => (
  <SectionBox
    key={index}
    align={data.align}
    title={data.title}
    desc={data.desc}
    image={data.image}
    bgColor={data.bgColor}
  />
));

export default function WaysofWorking() {
  const waysofworkingData = ServiceTitleSectionData.pages[0].waysofworking;

  return (
    <>
      <ServiceTitleSection
        title={waysofworkingData.title}
        ShortDesc={waysofworkingData.shortDesc}
        DetailedDesc={waysofworkingData.DetailedDesc}
        pageLink={waysofworkingData.link}
        image={waysofworkingData.image}
        themeColor={waysofworkingData.themeColor}
      />
      <div className="shape-blue-gradient shape-yellow-gradient">
        {sectionComponents}
      </div>
      <RelatedServices />

      <CalltoAction
        heading="simplify ways of working?"
        subheading="Start your 90 days experiment."
        color="black"
        themeColor="bg2"
        spacing="spacing"
      />
    </>
  );
}
