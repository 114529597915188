import { Row, Col } from "antd";
import reshapingFutureImg from "../../assets/images/ready-to-switch-gears.png";
import "./CTA.scss";
import ButtonBox from "../../Components/ButtonBox/ButtonBox";
import { useHistory } from "react-router-dom";

export default function CTA({ className, bgColor }) {
  const history = useHistory();

  return (
    <section className={`cta ${className}`}>
      <div className="container">
        <div className={`border-rounded ${bgColor || "bg2"}`}>
          <Row
            gutter={[
              { xs: 0, sm: 12, md: 12, lg: 12 },
              { xs: 0, sm: 12, md: 12, lg: 12 },
            ]}
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              data-aos="fade-up"
            >
              <span className="d-block font-weight-medium">
                Ready to switch gears?
              </span>
              <h3>Join us in reshaping the future of work!</h3>
              <p className="text-gray">
                Let's make working exciting and impactful. Wake up eager, not
                exhausted.
              </p>
              <ButtonBox
                variant="primary"
                label="Join Today"
                onClick={() => history.push("/contact-us")}
                rightArrowIcon
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              data-aos="fade-up"
            >
              <img
                src={reshapingFutureImg}
                alt="Simple Ideas GmbH | Join us in reshaping the future of work!"
              />
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
}
