import React, { useEffect, useState } from "react";
import { Row, Col, Affix, Breadcrumb, Divider } from "antd";
import { useHistory } from "react-router-dom";
import PostData from "./PostData";
import "./Blogs.scss";
import CTA from "../LandingPage/CTA";
import SocialShare from "../../Components/SocialShare/SocialShare";
import AssetPath from "../../AssetPath/AssetPath";
import RecentPosts from "../LandingPage/RecentPosts";

export default function BlogLayout({
  children,
  pageTitle,
  updatedOn,
  minRead,
}) {
  const history = useHistory();

  const [randomBlogs, setRandomBlogs] = useState([]);

  useEffect(() => {
    const getRandomBlogs = () => {
      const shuffled = PostData.sort(() => 0.5 - Math.random());

      return shuffled.slice(0, 4);
    };

    setRandomBlogs(getRandomBlogs());
  }, []);

  const BreadcrumbItems = [
    {
      title: "Home",
      href: "/",
    },
    {
      title: "Blogs",
      href: "/blogs",
    },
    {
      title: pageTitle,
    },
  ];

  return (
    <>
      <section className="bg2 spacing">
        <div className="container">
          <Row justify="center">
            <Col xs={24} sm={24} md={18} lg={18}>
              <img
                src={AssetPath.images.cta}
                className="mt-4 w-100 cursor-pointer"
                onClick={() => history.push("/contact-us")}
                alt="Simple Ideas GmbH"
              />
            </Col>
          </Row>
        </div>
      </section>
      <section className="bg-white pt-3">
        <div className="container">
          <Breadcrumb>
            {BreadcrumbItems.map((item, i) => (
              <Breadcrumb.Item key={i} href={item.href}>
                {item.title}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
          <Divider />
        </div>
      </section>
      <section className="content-area bg-white spacing-bottom">
        <div className="container">
          <Row justify="center" gutter={[20, 0]}>
            <Col xs={24} sm={24} md={4} lg={4}>
              <Affix offsetTop={120} className="sticky-social-share">
                <SocialShare />
              </Affix>
            </Col>

            <Col xs={24} sm={24} md={16} lg={16}>
              <h1>{pageTitle}</h1>
              {updatedOn ? (
                <p className="font-4 text-gray mb-4">
                  Updated On : {updatedOn} | {minRead} min read
                </p>
              ) : (
                ""
              )}

              {children}
            </Col>
            <Col xs={24} sm={24} md={4} lg={4}>
              <div className="recent-blogs">
                {randomBlogs.map((data, i) => (
                  <div
                    className="blogs cursor-pointer mb-3"
                    key={i}
                    onClick={() => history.push(`${data.text}`)}
                  >
                    <div className="image mb-2">
                      <img
                        src={data.thumbnail}
                        alt={data.title}
                        className=" w-100"
                      />
                    </div>
                    <h3 className="text-ellipse3 font-5 mb-2">{data.title}</h3>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <CTA bgColor="bg1" className="spacing-bottom" />

      {/* <section className="bg10 spacing">
        <div className="container">
          <h3 className="mb-3 text-center">
            Recent Blog Posts from Simple Ideas GmbH
          </h3>
          <Row
            justify="center"
            gutter={[
              { xs: 10, sm: 15, md: 20, lg: 20 },
              { xs: 10, sm: 15, md: 20, lg: 20 },
            ]}
          >
            {randomBlogs.map((data, i) => (
              <Col xs={24} sm={24} md={6} lg={6} key={i}>
                <div
                  className="blog-card cursor-pointer"
                  onClick={() => history.push(data.text)}
                >
                  <div className="thumbnail">
                    <img
                      src={data.thumbnail}
                      alt={data.title}
                      className="w-100"
                    />
                  </div>
                  <div className="details bg-white">
                    <h3 className="font-1 text-ellipse2">{data.title}</h3>
                    <p className="font-4 text-gray text-ellipse3 mb-0">
                      {data.shortDesc}
                    </p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </section> */}

      <RecentPosts />
    </>
  );
}
