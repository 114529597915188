import slide1 from "../../assets/images/slides/simplify.svg";
import slide2 from "../../assets/images/slides/excessive-complexity.svg";
import slide3 from "../../assets/images/slides/how-we-can-help.svg";
import slide4 from "../../assets/images/slides/what-value-we-can-bring.svg";
import slide5 from "../../assets/images/slides/success-stories.svg";

const HomeSliderData = [
  {
    title:
      "Why is there a need to adopt Simplicity as your core operating principle? ",
    desc: "Discover the power of streamlined efficiency in our approach to work",
    image: [slide1],
    keymessage: "Simplify",
    sectionId: "why-simplify",
  },
  {
    title: "Unnecessary and excessive complexity at work place:",
    desc: "Origin, Impact and the need for resolution ",
    image: [slide2],
    keymessage: "Complexity",
    sectionId: "excessive-complexity",
  },
  {
    title: "How we can help? Our offer",
    desc: "Our comprehensive offerings simplify achieving your objectives effortlessly.",
    image: [slide3],
    keymessage: "Services",
    sectionId: "our-offer",
  },
  {
    title: "What value we can bring to your organisation?",
    desc: "Maximizing efficiency, innovation, and growth for lasting success.",
    image: [slide4],
    keymessage: "Value",
    sectionId: "our-approach",
  },
  {
    title: "Success Stories “Simplicity in action”",
    desc: "Simplicity, the catalyst behind our impactful success stories.",
    image: [slide5],
    keymessage: "Clients",
    sectionId: "success-stories",
  },
];

export default HomeSliderData;
