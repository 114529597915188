import React from "react";
import { Row, Col, Collapse } from "antd";
import "./FAQ.scss";
import TitleBox from "../../Components/TitleBox/TitleBox";
import CTA from "../LandingPage/CTA";

const { Panel } = Collapse;
function callback(key) {
  console.log(key);
}

const collapseItem = [
  {
    key: 1,
    title: "How Does Simplifying Work Styles Help?",
    desc: "Discover how we bid farewell to complex work styles, embracing simplicity to transform routines into impactful, meaningful actions.",
  },
  {
    key: 2,
    title: "Why Embrace Simplicity for Innovation?",
    desc: "Learn how simplicity fuels innovation, driving ideas from conception to practical implementation for meaningful outcomes.",
  },
  {
    key: 3,
    title: "What Defines Action-Oriented Initiatives?",
    desc: "Explore our approach to initiatives—focused on practicality—to achieve objectives meaningfully, regardless of size.",
  },
  {
    key: 4,
    title: "How Does Simple Ideas GmbH Prioritize Impact?",
    desc: "Understand our strategy: embedding value and impact into organizational culture, reshaping perspectives for more potent outcomes.",
  },
  {
    key: 5,
    title: "What's the Essence of Impact Strategy?",
    desc: "Gain insights into navigating the intricate journey of strategic and tactical impact, streamlining your organizational approach.",
  },
  {
    key: 6,
    title: "What's the Value-based Portfolio Strategy?",
    desc: "Discover how we blend goals and data to provide clear insight into your initiative and investment portfolio for optimal strategy.",
  },
  {
    key: 7,
    title: "How Does Real-Time Measurement Boost Success?",
    desc: "Understand the importance of measuring progress in real-time and how our approach breaks down value stages for impactful insights.",
  },
  {
    key: 8,
    title: "How Does Impact Drive Decision Making?",
    desc: "Learn about our innovative approach, combining intuition and hard data for clear, confident decisions that drive success.",
  },
  {
    key: 9,
    title: "Why Foster Learning & Improvement Hubs?",
    desc: "Explore how we support organizations in making learning a foundation for enhancing future actions and initiatives.",
  },
  {
    key: 10,
    title: "How Does Simple Ideas GmbH Enable Real-time Insights?",
    desc: "Understand the significance of leveraging real-time insights and recommendations to optimize strategy and actions.",
  },
  {
    key: 11,
    title: "What's the Role of Simplicity in Transforming Routines?",
    desc: "Discover how simplicity plays a pivotal role in transforming routines into impactful, meaningful actions for success.",
  },
  {
    key: 12,
    title: "Why Prioritize Action-Oriented Approaches?",
    desc: "Learn how our practical approach to initiatives drives meaningful results regardless of their size or complexity.",
  },
  {
    key: 13,
    title: "What Makes Impact Strategy Essential?",
    desc: "Understand the significance of an impact strategy and its role in guiding organizational success and growth.",
  },
  {
    key: 14,
    title: "How Does Simple Ideas GmbH Measure Initiative Success?",
    desc: "Explore our method of measuring initiative success, breaking down value into expected, real, and perceived categories.",
  },
  {
    key: 15,
    title: "Why Focus on Real-Time Progress?",
    desc: "Learn about the importance of tracking progress in real-time and its impact on the success of organizational efforts.",
  },
  {
    key: 16,
    title: "How Does Impact Strategy Drive Decision Enablement?",
    desc: "Understand how our approach to impact strategy empowers confident decision-making for future organizational success.",
  },
  {
    key: 17,
    title: "What Makes Learning & Improvement Essential?",
    desc: "Discover the importance of making learning a foundation for continuous improvement and organizational success.",
  },
  {
    key: 18,
    title: "Why Rely on Real-Time Insights for Strategy?",
    desc: "Explore how leveraging real-time insights and market-driven recommendations optimizes organizational strategy.",
  },
  {
    key: 19,
    title: "What Role Does Simplicity Play in Driving Innovation?",
    desc: "Understand the pivotal role simplicity plays in fostering innovative thinking and driving practical, impactful solutions.",
  },
  {
    key: 20,
    title: "How Does Simple Ideas GmbH Drive Impactful Change?",
    desc: "Explore our approach to impactful change, leveraging simplicity and action-oriented strategies for meaningful transformation.",
  },
];

export default function FAQ() {
  return (
    <React.Fragment>
      <TitleBox title="Frequently Asked Questions" align="center" />
      <section className="faq spacing light-background">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            className="pb-3 "
            align="center"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 18 }}
              lg={{ span: 18 }}
            >
              <div class="highlight-title " data-aos="fade-up">
                <Collapse ghost onChange={callback} expandIconPosition="right">
                  {collapseItem.map((list, i) => (
                    <Panel header={list.title} key={list.i}>
                      <p className="font-4 text-gray">{list.desc}</p>
                    </Panel>
                  ))}
                </Collapse>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <CTA bgColor="bg2" />
    </React.Fragment>
  );
}
