import { Col, Row } from "antd";
import React from "react";
import "../Contact/Contact.scss";
import halfCircleRight from "../../assets/images/shapes/half-circle-right.svg";
import PartnersForm from "./PartnersForm";
import becomePartner from "../../assets/images/partner-with-us.svg";

export default function Partners() {
  return (
    <>
      <section className="title-box bg-yellow-lineargradient position-relative">
        <div className="container text-center">
          <img
            src={halfCircleRight}
            className="circle-half position-absolute"
            alt="SimpleIdeas GmbH"
          />
          <Row
            gutter={[
              { xs: 0, sm: 12, md: 12, lg: 12 },
              { xs: 0, sm: 12, md: 12, lg: 12 },
            ]}
            justify="center"
            align="middle"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 16 }}
              lg={{ span: 12 }}
              data-aos="fade-up"
            >
              <h1 className="h2">Become a Partner</h1>
              <p className="text-gray font-4">
                Join us, become a valued partner in our journey towards shared
                success
              </p>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 16 }}
              lg={{ span: 16 }}
              data-aos="fade-up"
            >
              <img
                src={becomePartner}
                alt="Become a partners"
                className="w-100"
              />
            </Col>
          </Row>
        </div>
      </section>
      <section className="contact-support spacing-bottom light-background">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            className="pb-3 "
            align="middle"
            justify="center"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 14 }}
              lg={{ span: 14 }}
              data-aos="fade-up"
            >
              <div class="highlight-title" data-aos="fade-up">
                <h3 className="text-center">
                  Just fill out the form and hit submit
                </h3>
                <p className="text-gray text-center mb-3">
                  we're here to assist.
                </p>
                <PartnersForm />
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
